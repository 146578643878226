<template>
  <page-layout>
    <div class="heading">
      <h1 class="main-title">
        Tolerable for sensitive rosacea skin<sup>1</sup>
      </h1>
    </div>
    <div class="tabs-button">
      <router-link :to="{ name: 'tolerability-common-adverse-reactions-tab' }">
        <button class="btn-tab1 omnes-med">COMMON ADVERSE REACTIONS</button>
      </router-link>
      <router-link :to="{ name: 'tolerability-twelve-weeks' }">
        <button class="btn-tab3 omnes-reg">TOLERABILITY OVER 12 WEEKS</button>
      </router-link>
      <router-link :to="{ name: 'tolerability-long-term-use-tab' }">
        <button class="btn-tab2 omnes-reg">LONG-TERM USE</button>
      </router-link>
    </div>
    <div class="container">
      <h3 class="title-description omnes-reg">
        Adverse reactions reported by ≥1% of patients in the 12-week pivotal
        trials treated with <br />EPSOLAY cream and more frequently than with
        vehicle cream, all on application site<sup>1</sup>
      </h3>
      <img
        class="expand-icon"
        src="@/assets/images/svg/expand-icon.svg"
        alt=""
        @click="openModal('AdverseReactionChartMax')"
      />
      <img
        class="chart-image"
        src="@/assets/images/charts/adverse-reactions-table.svg"
        alt=""
      />
      <hr class="footer-bar" />
      <div class="container__mini-text">
        <p>
          *Application site edema includes application site swelling and
          application site edema.<sup>1</sup>
        </p>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";

export default {
  components: {
    PageLayout,
  },
  methods: {
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
};
</script>

<style lang="scss" scoped>
.tolerability-common-adverse-reactions-tab {
  .heading {
    margin-top: 60px;
    margin-left: 40px;
  }
  .tabs-button {
    margin-top: 55px;
    margin-left: 40px;
    margin-bottom: -2px;
    .btn-tab1,
    .btn-tab2,
    .btn-tab3 {
      color: $epsolay-dark-green;
      font-size: 13px;
      letter-spacing: 0.8px;
      padding-left: 15px;
      padding-right: 20px;
      cursor: pointer !important;
      margin: 0;
    }
    .btn-tab1 {
      color: $white;
      background: $epsolay-dark-green;
      padding-top: 5px;
      border: 1px solid $epsolay-dark-green;
      border-radius: none;
      padding: 7px 10px;
    }
    .btn-tab2 {
      background: $epsolay-modal;
      border: 1px solid $epsolay-modal;
      border-radius: none;
      padding: 7px 50px;
      border-left: 1px solid $epsolay-dark-green;
    }
    .btn-tab3 {
      background: $epsolay-modal;
      border: 1px solid $epsolay-modal;
      border-right: 1px solid $epsolay-dark-green;
      border-radius: none;
      padding: 7px 20px;
    }
  }
  .container {
    display: inline-block;
    width: 900px;
    height: 560px;
    background: $epsolay-dark-green;
    .title-description {
      margin-top: 30px;
      margin-left: 5px;
      font-size: 16px;
      text-align: center;
      color: $epsolay-accent;
      font-weight: 600;
      sup {
        font-size: 18px;
      }
    }
    .expand-icon {
      position: absolute;
      right: 330px;
      top: 315px;
    }
    .chart-image {
      width: 470px;
      height: 500px;
      margin-left: 200px;
      margin-top: -71px;
      margin-bottom: 15px;
      z-index: 1;
    }
    .footer-bar {
      border: 0.5px solid $epsolay-accent;
      width: 830px;
      margin-top: -59px;
      margin-left: 30px;
    }
    &__mini-text {
      margin-left: 30px;
      p {
        color: $white;
        font-family: "Omnes Regular";
        font-size: 12.3px;
        letter-spacing: 2%;
        sup {
          font-size: 15px;
          vertical-align: sub;
        }
      }
    }
  }
}
</style>
