<template>
  <div class="patient-photos-comp content">
    <div class="content-wrapper">
      <div class="col patients-nav">
        <a
          @click="showPatient('patient-1')"
          :class="{ active: activeContent == 'patient-1' }"
          >Patient 1<sup>13</sup></a
        >
        <a
          @click="showPatient('patient-2')"
          :class="{ active: activeContent == 'patient-2' }"
          >Patient 2<sup>8</sup></a
        >
        <a
          @click="showPatient('patient-3')"
          :class="{ active: activeContent == 'patient-3' }"
          >Patient 3<sup>13</sup></a
        >
        <a
          @click="showPatient('patient-4')"
          :class="{ active: activeContent == 'patient-4' }"
          >Patient 4<sup>8</sup></a
        >
      </div>
      <div class="row patients-section">
        <div
          class="patient patient-1"
          :class="{ active: activeContent == 'patient-1' }"
        >
          <patient-one></patient-one>
        </div>
        <div
          class="patient patient-2"
          :class="{ active: activeContent == 'patient-2' }"
        >
          <patient-two></patient-two>
        </div>
        <div
          class="patient patient-3"
          :class="{ active: activeContent == 'patient-3' }"
        >
          <patient-three></patient-three>
        </div>
        <div
          class="patient patient-4"
          :class="{ active: activeContent == 'patient-4' }"
        >
          <patient-four></patient-four>
        </div>
      </div>
      <div class="row iga-score">
        <img src="@/assets/images/svg/iga-score-chart-v2.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import PatientOne from "@/components/Efficacy/patients/PatientOne.vue";
import PatientTwo from "@/components/Efficacy/patients/PatientTwo.vue";
import PatientThree from "@/components/Efficacy/patients/PatientThree.vue";
import PatientFour from "@/components/Efficacy/patients/PatientFour.vue";
import { sendTracking } from "@/mixins/tracking";

export default {
  components: {
    PatientOne,
    PatientTwo,
    PatientThree,
    PatientFour,
  },
  data() {
    return {
      activeContent: "patient-1",
    };
  },
  methods: {
    showPatient(content) {
      this.activeContent = content;
      switch (content) {
        case "patient-1":
          sendTracking(978);
          break;
        case "patient-2":
          sendTracking(984);
          break;
        case "patient-3":
          sendTracking(990);
          break;
        case "patient-4":
          sendTracking(996);
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.patient-photos-comp.content {
  height: 100vh;
  margin-top: 70px;
  background: $epsolay-dark-green-2;
  padding-left: 35px;
  padding-right: 140px;
  .content-wrapper {
    position: relative;
    .patients-nav {
      position: absolute;
      top: 47px;
      left: 2px;
      a {
        font-family: "Omnes Medium";
        color: $white;
        font-size: 12px;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 1.2px;
        display: block;
        margin-bottom: 15px;
        &.active {
          font-family: "Omnes Medium";
          color: $epsolay-accent;
          position: relative;
          &::after {
            content: "";
            display: inline-block;
            width: 85%;
            height: 1px;
            background: $epsolay-accent;
            position: absolute;
            top: 17px;
            left: 0;
          }
        }
      }
    }
    .patients-section {
      padding-top: 50px;
      display: flex;
      margin-left: 95px;
      .patient {
        display: none;
        &.active {
          display: block;
        }
      }
      .container {
        display: flex;
        .col-2 {
          width: 50%;
          text-align: center;
          img {
            width: 540px;
          }
          .btn-weeks {
            margin-top: 10px;
            margin-left: 147px;
            text-align: center;
            position: relative;
            .expand-icon {
              position: absolute;
              width: 20px;
              margin-left: 10px;
              margin-top: 1px;
            }
            a {
              font-size: 10px;
              color: $white;
              text-transform: uppercase;
              text-decoration: none;
              letter-spacing: 1.2px;
              margin-right: 3px;
              border: 1px solid $white;
              padding: 5px 7px;
              &.active {
                background: $epsolay-accent;
                color: $epsolay-dark-green;
                border: 1px solid $epsolay-accent;
              }
            }
          }
        }
        .col-3 {
          margin-left: 160px;
          .info-success {
            p {
              font-size: 12px;
              color: $epsolay-accent-2;
              text-transform: uppercase;
              display: inline-block;
              border: 1px solid rgba($color: $epsolay-accent-2, $alpha: 0.5);
              border-radius: 10px;
              padding: 6px 15px;
              letter-spacing: 1.2px;
              margin-top: 0;
              margin-bottom: 20px;
            }
          }
          .patient-info {
            p {
              margin-left: -7px;
            }
            p,
            span {
              font-size: 11px;
              color: $white;
            }
            span {
              letter-spacing: -0.7px;
            }
          }
        }
      }
    }
    .iga-score {
      margin-top: 50px;
      img {
        width: 820px;
      }
    }
  }
}
</style>
