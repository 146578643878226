<template>
  <div class="content">
    <div class="content__column-1">
      <hr class="line-1" />

      <h4 class="title-1 omnes-reg">MANY PATIENTS WERE</h4>
      <img alt="" src="../../assets/images/svg/head-icon.svg" />
      <hr class="line-2" />

      <p class="mini-text">IGA=Investigator Global Assessment.</p>
      <p class="mini-text">
        <span style="margin-left: -5px">*</span>The coprimary efficacy endpoints
        in both trials were the proportion of patients with
        <span class="omnes-med-ita">treatment success at Week 12,</span> defined
        as an IGA score of 0 (clear) or 1 (almost clear) with at least a 2-grade
        reduction from baseline, and the<br />
        absolute change from baseline in inflammatory lesion<br />
        counts at Week 12.<sup>1</sup>
      </p>
      <p class="mini-text" style="text-indent: -4px">
        <sup style="font-size: 11px">†</sup>Based on multiple imputation
        analysis. In Pivotal Trial 1, 43.5% of subjects treated with EPSOLAY
        cream achieved IGA success vs 16.1% for vehicle (<span class="omnes-ita"
          >P</span
        >&#60;0.001). The respective values in Pivotal Trial 2 were 50.1% and
        25.9% (<span class="omnes-ita">P</span>&#60;0.001).<sup
          >8,13<span
            style="
              font-size: 6px;
              display: inline-block;
              vertical-align: text-top;
              margin-left: 2px;
              font-style: italic;
            "
          ></span
        ></sup>
      </p>
    </div>

    <div class="content__column-2">
      <h3 class="omnes-reg">IGA success* at Week 12<sup>1†</sup></h3>
      <img src="../../assets/images/charts/iga-success-chart.svg" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.efficacytrialresultsdata3 {
  .modal-layout__content {
    width: 1050px;
    height: 734px;
    background: $epsolay-dark-green;
    .modal-layout__close {
      position: absolute;
      top: 60px;
      right: 19px;
      z-index: 100;
      width: 24px;
      height: 24px;
      background: url("../../assets/images/svg/close-button.svg") no-repeat;
      background-size: cover;
    }
    .content {
      display: flex;
      justify-content: space-between;
      margin-top: -30px;
      margin-left: -40px;
      height: 734px;
      &__column-1 {
        background: $epsolay-light-green;
        width: 300px;
        padding: 16px;
        img {
          display: block;
          margin: auto;
          width: 200px;
        }
        .line-1,
        .line-2 {
          border: 0.1px solid $epsolay-accent-2;
          width: 230px;
        }
        .line-1 {
          margin-top: 180px;
        }
        .line-2 {
          margin-top: 25px;
          margin-bottom: 60px;
        }
        .title-1 {
          color: $epsolay-accent-2;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 1px;
          text-align: center;
          margin-top: 20px;
          margin-bottom: 10px;
        }
        .subtitle-text-1 {
          color: #d8d4fa;
          font-size: 15px;
          text-align: center;
          margin-top: 15px;
          letter-spacing: 1px;
          margin-bottom: 12px;
          sup {
            font-size: 16px;
          }
        }
        .mini-text {
          color: #fff;
          font-size: 11.2px;
          letter-spacing: 0.1px;
          margin-left: 20px;
          margin-bottom: 2px;
          sup {
            font-size: 10px;
          }
        }
      }
      &__column-2 {
        background: $epsolay-dark-green;
        width: 700px;
        height: 710px;
        margin-left: 30px;
        h3 {
          color: $epsolay-accent;
          font-size: 30px;
          font-weight: 600;
          text-align: center;
          margin-top: 90px;
          margin-bottom: 50px;
          line-height: 1;
        }
        img {
          width: 600px;
          display: block;
          margin: auto;
          position: relative;
          top: 10px;
        }
      }
    }
  }
}
</style>
