<template>
  <div class="content">
    <div class="content__column-1">
      <h4 class="title-1 omnes-reg">IN AS LITTLE AS</h4>
      <img src="../../assets/images/svg/2-weeks.svg" />
      <p class="subtitle-text-1">
        patients saw<br />visible results<sup>8,13</sup>
      </p>
      <hr class="line-1" />

      <h4 class="title-2 omnes-reg">
        <span class="omnes-sembold">EPSOLAY CREAM</span><br />REDUCED NEARLY
      </h4>
      <img src="../../assets/images/svg/70-percent-chart.svg" />
      <p class="subtitle-text-2">
        of inflammatory<br />lesions by Week 12<sup>1</sup>
      </p>
      <hr class="line-2" />
      <p class="mini-text">
        <span style="margin-right: 2px; font-size: 12px">*</span>Multiple
        imputation was used to impute missing values. Percent
        <span style="margin-left: 5px"
          >change at Week 2 represents the mean value obtained from
        </span>
        <span style="margin-left: 5px"
          >averaging the summary statitics generated from each imputed
        </span>
        <span style="margin-left: 5px"
          >data set (Table 14.2.2.1.1, CSR). Percent change at Week 12
        </span>
        <span style="margin-left: 5px"
          >represents the LS mean (Table 3, Prescribing Information).</span
        ><sup style="font-size: 12px">1,8,13</sup>
      </p>
    </div>

    <div class="content__column-2">
      <h3 class="omnes-reg">
        Mean absolute change in<br />inflammatory lesion counts over time*
      </h3>
      <div class="content-buttons">
        <button
          @click="openModal('EfficacyTrialResultsData1')"
          class="btn-modal-1"
        >
          <span class="omnes-cond-med text-1"
            >Pivotal Trial 1<sup>1,8</sup></span
          >
          <br />
          <span class="omnes-med-ita"> P&lt;</span
          ><span class="data-number">0.001</span>
        </button>

        <button
          @click="openModal('EfficacyTrialResultsData2')"
          class="btn-modal-2"
        >
          <span class="omnes-cond-med text-1"
            >Pivotal Trial 2<sup>1,13</sup></span
          >
          <br />
          <span class="omnes-med-ita"> P&lt;</span
          ><span class="data-number">0.001</span>
        </button>
      </div>
      <div class="content-img">
        <img src="../../assets/images/charts/pivotal-trial-2.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
};
</script>

<style lang="scss">
.efficacytrialresultsdata2 {
  .modal-layout__content {
    width: 1050px;
    height: 734px;
    background: $epsolay-dark-green;
    .modal-layout__close {
      position: absolute;
      top: 60px;
      right: 19px;
      z-index: 100;
      width: 24px;
      height: 24px;
      background: url("../../assets/images/svg/close-button.svg") no-repeat;
      background-size: cover;
    }
    .content {
      display: flex;
      justify-content: space-between;
      margin-top: -30px;
      margin-left: -40px;
      height: 734px;
      &__column-1 {
        background: $epsolay-light-green;
        width: 300px;
        padding: 16px;
        img {
          display: block;
          margin: auto;
          width: 155px;
        }
        .line-1,
        .line-2 {
          border: 0.1px solid #d8d4fa;
          width: 230px;
        }
        .line-2 {
          margin-bottom: 40px;
        }
        .title-1 {
          color: #d8d4fa;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 1px;
          text-align: center;
          margin-top: 40px;
          margin-bottom: 10px;
        }
        .title-2 {
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 1px;
          text-align: center;
          margin-top: 20px;
          margin-bottom: 10px;
        }
        .subtitle-text-1 {
          color: #d8d4fa;
          font-size: 15px;
          text-align: center;
          margin-top: 15px;
          letter-spacing: 1px;
          margin-bottom: 12px;
          sup {
            font-size: 18px;
          }
        }
        .subtitle-text-2 {
          color: #fff;
          font-size: 15px;
          text-align: center;
          margin-top: 15px;
          letter-spacing: 1px;
          margin-bottom: 30px;
          sup {
            font-size: 18px;
          }
        }
        .mini-text {
          color: #fff;
          font-size: 11.2px;
          letter-spacing: -0.3px;
          text-align: left;
          margin-left: 19px;
        }
      }
      &__column-2 {
        background: $epsolay-dark-green;
        width: 700px;
        height: 500px;
        margin-left: 30px;
        h3 {
          color: $epsolay-accent;
          font-size: 30px;
          font-weight: 600;
          text-align: center;
          margin-top: 75px;
          margin-bottom: 20px;
          line-height: 1;
        }
        img {
          width: 596px;
          display: block;
          margin: auto;
          margin-left: 24px;
        }
        .content-buttons {
          display: flex;
          justify-content: center;
          .btn-modal-1,
          .btn-modal-2 {
            padding: 9px;
            color: $white;
            border: 1px solid $white;
            cursor: pointer;
            margin: 10px;
            span {
              font-size: 12px;
              sup {
                font-size: 18px;
              }
            }
            .text-1 {
              font-size: 18px;
            }
          }
          .btn-modal-1 {
            background-color: $epsolay-dark-green;
          }
          .btn-modal-2 {
            background-color: #6b799a;
          }
          .omnes-med-ita {
            .data-number {
              font-style: normal;
            }
          }
        }
        .content-img {
          margin-left: 18px;
        }
      }
    }
  }
}
</style>
