<template>
  <page-layout>
    <div class="content">
      <img
        class="microencapsulation-tech-image"
        src="@/assets/images/jpg-png/microencapsulation-logo.png"
      />
      <div class="heading-title">
        <h1 class="analogue-reg">
          Rosacea <br />
          treatment <br />
          unlike <br />
          any other<sup class="analogue-reg">1,2*</sup>
        </h1>
      </div>
      <div class="feature-copy omnes-cond-reg">
        <p>
          The BPO you know, now made suitable for the inflammatory lesions of
          rosacea.
        </p>
        <p>BPO=benzoyl peroxide.</p>
      </div>
      <div class="footnote omnes-cond-reg">
        <p>Not an actual patient.</p>
      </div>
      <div class="galderma-logo">
        <img src="@/assets/images/svg/galderma-logo.svg" alt="" />
      </div>
      <div class="text-footer">
        <small class="omnes-reg"
          >*The only approved rosacea therapy with silica microencapsulation
          technology.</small
        >
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";

export default {
  components: {
    PageLayout,
  },
};
</script>

<style lang="scss">
.home {
  background: url("~@/assets/images/jpg-png/bg-home-v3.jpg");
  background-size: 929.5px;
  background-repeat: no-repeat;
  background-position: -54px 0px;

  .content {
    .microencapsulation-tech-image {
      width: 114.5px;
      position: absolute;
      right: 185px;
      top: 37.5px;
    }

    .heading-title {
      position: absolute;
      bottom: 109px;
      left: 35px;

      h1 {
        font-size: 48px;
        color: #fff;
        line-height: 1;
        letter-spacing: -0.2px;

        sup {
          font-size: 30px;
          position: relative;
          top: -15px;
        }
      }
    }

    .feature-copy {
      position: absolute;
      left: 35px;
      color: #fff;
      bottom: 98px;

      p {
        font-size: 7.5px;
        margin: 0;
        margin-bottom: 5px;
      }
    }

    .copy-logo {
      position: absolute;
      right: 191px;
      bottom: 28px;

      p {
        font-size: 12px;
        color: $epsolay-accent;
        letter-spacing: 1.2px;
      }
    }

    .footnote {
      color: #fff;
      font-size: 7.5px;
      position: absolute;
      bottom: 10px;
      right: 190px;
    }

    .galderma-logo {
      position: absolute;
      bottom: 33px;
      left: 32px;

      img {
        width: 103px;
      }
    }
    .text-footer {
      position: absolute;
      left: 32px;
      bottom: 16px;
      small {
        font-size: 7.5px;
        color: #fff;
        sup {
          font-family: "Omnes Regular";
          font-weight: normal;
          font-style: normal;
          font-display: swap;
          font-size: 6px;
          vertical-align: super;
        }
      }
    }
  }
}
</style>
