<template>
  <div class="content">
    <div class="expanded-chart">
      <img
        class="iga-four-week"
        src="@/assets/images/svg/iga-four-week.svg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
};
</script>

<style lang="scss">
.fourweekigasuccessmodal {
  .modal-layout__content {
    width: 1050px;
    height: 734px;
    background: $epsolay-dark-green;
    .modal-layout__close {
      position: absolute;
      top: 60px;
      right: 19px;
      z-index: 100;
      width: 24px;
      height: 24px;
      background: url("../../assets/images/svg/close-button.svg") no-repeat;
      background-size: cover;
    }
    .content {
      display: flex;
      justify-content: space-between;
      margin-top: -30px;
      margin-left: -40px;
      height: 734px;
      .expanded-chart {
        position: absolute;
        top: 160px;
        left: 125px;
      }
    }
  }
}
</style>
