<template>
  <page-layout>
    <div class="heading">
      <h1 class="main-title">
        Take a deeper look beneath the surface<br />of rosacea
      </h1>
    </div>
    <div class="container">
      <div class="container__inner">
        <div class="tabs-buttons">
          <router-link :to="{ name: 'rosacea-burden-tab' }">
            <button class="btn-tab1 omnes-med">BURDEN</button>
          </router-link>

          <router-link :to="{ name: 'rosacea-treatment-tab' }">
            <button class="btn-tab2 omnes-reg">TREATMENT</button>
          </router-link>
        </div>
        <div class="container__title-patients">
          <h3
            class="subtitle"
            style="font-size: 17px; text-transform: uppercase"
          >
            Rosacea patients may seek treatment because of the cumulative<br />
            burden of their disease, signs, symptoms, and
            <span style="text-transform: capitalize">QoL</span>
            impact<sup style="font-size: 18px">3</sup>
          </h3>
        </div>
        <div class="container__content-columns">
          <div class="column-1">
            <h3 class="title-column-1">
              MANY PATIENTS<sup style="font-size: 16px">4,5</sup>:
            </h3>
            <ul class="omnes-cond-reg">
              <li>Practice stress and/or anxiety<br />management</li>
              <li>Use makeup to cover rosacea</li>
              <li>
                Avoid sun exposure, hot baths<br />
                and/or saunas, certain skincare<br />
                products, specific foods, alcohol,<br />
                and hot beverages
              </li>
            </ul>
          </div>
          <div class="column-2">
            <img
              class="icon-2"
              src="@/assets/images/jpg-png/icon-2.png"
              width="80"
              alt=""
            />
            <p class="omnes-cond-reg">
              High-burden patients report<br />
              spending up to <span class="omnes-cond-bold">3.5 hours</span> more
              on<br />
              their skincare regimen per week<sup style="font-size: 16px"
                >5</sup
              >
            </p>
          </div>
          <div class="column-3">
            <img
              class="icon-3"
              src="@/assets/images/jpg-png/icon-3.png"
              width="80"
              alt=""
            />
            <p class="omnes-cond-reg">
              <span class="omnes-cond-bold">Approximately 50%</span> of
              rosacea<br />
              patients report suffering from<br />
              at least moderate depression<sup style="font-size: 16px">6</sup>
            </p>
          </div>
        </div>
        <div class="container__button-survey">
          <button
            class="btn-survey-design"
            @click="openModal('rosacea-burden-survey-modal')"
          >
            See survey design
            <span>
              <img
                class="arrow-black"
                src="@/assets/images/svg/arrow-3.svg"
                width="80"
                alt=""
              />
            </span>
          </button>
          <div class="footnote"><sup>QoL=quality of life.</sup></div>
        </div>
      </div>
      <div class="container__footer">
        <p>Are patients telling you how deeply rosacea affects them?</p>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";

export default {
  components: {
    PageLayout,
  },
  methods: {
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
};
</script>

<style lang="scss" scoped>
.rosacea-burden-tab {
  button {
    color: black;
  }
  background: $white;
  .heading {
    display: flex;
    align-items: center;
    margin: 0;
    height: 162px;
    letter-spacing: 1.5px;
    padding-left: 40px;
    h1 {
      margin: 0;
    }
  }
  .tabs-buttons {
    a {
      display: inline-block;
      color: black;
    }
    .btn-tab1,
    .btn-tab2 {
      font-size: 14px;
      letter-spacing: 0.8px;
      padding: 5px 22px;
      cursor: pointer;
      margin: 0;
      border: 0 none;
    }
    .btn-tab1 {
      background: $epsolay-accent-2;
      border: 0 none;
      font-weight: 500;
      border-radius: 0;
    }
    .btn-tab2 {
      background: $epsolay-accent-2a;
      border-radius: none;
      font-weight: 500;
    }
  }
  .container {
    display: flex;
    justify-content: space-around;
    width: 888px;
    height: 562px;
    flex-flow: column nowrap;
    justify-content: space-between;
    &__inner {
      width: 803px;
      margin: 0 auto;
    }
    &__title-patients {
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-top: 1px solid $epsolay-dark-text;
      border-bottom: 1px solid $epsolay-dark-text;
      .subtitle {
        letter-spacing: 0.8px;
        font-family: "Omnes Regular";
        font-weight: 600;
        color: $epsolay-dark-text;
        margin: 0;
      }
    }
    &__content-columns {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      border-bottom: 1px solid $epsolay-dark-text;
      .column-1,
      .column-2,
      .column-3 {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 258px;
      }
      .column-1 {
        background: #fff8f6;
        width: 190px;
        h3 {
          font-size: 13.5px;
          margin-top: 35px;
          margin-left: 13px;
          font-family: "Omnes Regular";
          text-align: center;
          font-weight: 500;
          letter-spacing: 1.2px;
        }
        ul {
          margin-top: 1px;
          margin-left: 12px;
          line-height: 23px;
          li::marker {
            color: $epsolay-accent-2;
          }
          li {
            font-size: 16px;
            letter-spacing: 0.2px;
          }
        }
      }
      .column-2,
      .column-3 {
        display: flex;
        align-items: center;
        p {
          margin-top: 30px;
          font-size: 16px;
          line-height: 18px;
          text-align: center;
        }
        .icon-2,
        .icon-3 {
          width: 90px;
          margin-top: 45px;
        }
      }
      .column-3 {
        background: #fff8f6;
        p > span {
          letter-spacing: -0.5px;
        }
      }
    }
    &__button-survey {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
      height: 98px;
      position: relative;
      .btn-survey-design {
        font-size: 9px;
        text-transform: uppercase;
        border: 1px solid #000;
        background: $white;
        padding: 10px 18px;
        height: 35px;
        letter-spacing: 1px;
        cursor: pointer;
        span > .arrow-black {
          width: 26px;
          margin-left: 8px;
        }
      }
      .footnote {
        font-size: 8px;
        position: absolute;
        bottom: 10px;
        left: 0;
      }
    }
    &__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      background: #ffede8;
      p {
        font-family: "Omnes Regular";
        font-size: 15px;
        font-weight: 600;
        letter-spacing: -0.5px;
        margin: 0;
      }
    }
  }
}
</style>
