<template>
  <page-layout>
    <div class="content">
      <div class="heading">
        <h1 class="main-title">Success as early as Week 2<sup>8,13</sup></h1>
      </div>
      <div class="tabs">
        <ul>
          <li class="tab-item">
            <router-link :to="{ name: 'efficacy' }"
              >Pivotal trial results</router-link
            >
          </li>
          <li class="tab-item meta-analysis-onset-item">
            <router-link :to="{ name: 'meta-analysis-onset' }"
              >Meta-analysis (onset)</router-link
            >
          </li>
          <li class="tab-item iga-success-results">
            <router-link :to="{ name: 'iga-success' }"
              >Meta-analysis (IGA)</router-link
            >
          </li>
          <li class="p-photos-item">
            <router-link :to="{ name: 'patient-photos' }"
              >Patient Photos</router-link
            >
          </li>
        </ul>
      </div>
      <pivotal-trial-results></pivotal-trial-results>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";
import PivotalTrialResults from "@/components/Efficacy/PivotalTrialResults.vue";

export default {
  components: {
    PageLayout,
    PivotalTrialResults,
  },
  methods: {
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
};
</script>

<style lang="scss">
.efficacy {
  .content {
    .heading {
      margin-top: 55px;
      margin-left: 35px;
      .main-title {
        margin: 0;
        font-size: 55px;
      }
    }
    .tabs {
      position: absolute;
      left: -22px;
      top: 137px;
      ul {
        li {
          display: inline-block;
          a {
            text-decoration: none;
            text-transform: uppercase;
            font-family: "Omnes Regular";
            font-size: 14px;
            color: $epsolay-dark-green;
            background: $epsolay-tabs;
            display: inline-block;
            text-align: center;
            letter-spacing: 1px;
            width: 200px;
            padding: 5px 0;
            &.router-link-exact-active {
              background: $epsolay-dark-green-2;
              color: $white;
              font-family: "Omnes Medium";
            }
          }
          &.p-photos-item {
            a {
              border-left: 1px solid $epsolay-dark-green;
            }
          }
          &.meta-analysis-onset-item {
            a {
              padding-left: 20px;
              padding-right: 20px;
              border-right: 1px solid $epsolay-dark-green;
            }
          }
        }
      }
    }
  }
}
</style>
