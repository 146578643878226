<template>
  <div class="content">
    <div class="content__text-description">
      <h4 class="omnes-reg">SURVEY DESIGN<sup>5</sup></h4>
      <div class="content-wrapper">
        <p class="omnes-cond-reg">
          <span class="omnes-cond-bold">Methods: </span>self-administered online
          survey covered 554 physicians and 710 patients from France,
          Germany,<br />
          Italy, the UK, Canada, and the US. Participants were recruited using
          the Kantar online panel. In order to maximize<br />
          the sample size, no quota was set up. Therefore, the sample is not
          representative of the rosacea population of<br />
          each country.
        </p>
        <p class="omnes-cond-reg">
          <span class="omnes-cond-bold">Inclusion criteria for patients: </span
          >adults from 18 to 70 years of age, suffering with rosacea as
          diagnosed by a<br />
          physician (declarative), having taken a prescribed treatment in the
          past 12 months, and visited a physician<br />
          at least twice in the past 12 months.
        </p>
        <p class="omnes-cond-reg">
          <span class="omnes-cond-bold"
            >Inclusion criteria for physicians: </span
          >dermatologists or GPs (GPs in Canada and UK only) from 25 to 70 years
          of age,<br />
          with at least 2 years of practice; at least 50% of their time spent in
          direct patient care; and at least 40 patients<br />
          seen per month (15 for GPs) with any indication. They must personally
          initiate therapy for patients suffering with<br />
          rosacea. The minimum number of patients with rosacea seen per month
          was as follows: US=40, Canada=30,<br />
          Germany and UK=20, France and Italy=10, GPs (all countries)=10.
        </p>
        <small class="omnes-cond-reg">GP=general practitioner.</small>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.rosacea-burden-survey-modal {
  .modal-layout__content {
    background: #fdddd4;
    padding: 15px 20px 20px 20px;
    min-height: 470px;
    .content {
      &__text-description {
        margin-left: 20px;
        h4 {
          font-size: 16.5px;
          letter-spacing: 1.7px;
          sup {
            font-size: 15.8px;
          }
        }
        sup {
          font-size: 10px;
        }
      }
      &-wrapper {
        color: $epsolay-dark-text;
        p {
          line-height: 1.4;
        }
        small {
          display: inline-block;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
