<template>
  <div class="content">
    <!-- <div class="content-video">
      <video
        width="810"
        height="500"
        controls
        src="@/assets/video/science-video.mp4"
        type="video/mp4"
      ></video>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.science-mod-video-modal {
  .modal-layout__close {
    position: absolute;
    top: 13px;
    right: 20px;
    z-index: 100;
    width: 30px;
    height: 30px;
    background: transparent url("~@/assets/images/svg/close-button.svg")
      no-repeat;
  }
  .modal-layout__content {
    background: #2e5a57;
    .content {
      width: 810px;
      height: 500px;
      .content-video {
        img {
          display: flex;
          justify-content: center;
          width: 886px;
          height: 531px;
          margin-left: -38px;
          margin-top: -31px;
        }
      }
    }
  }
}
</style>
