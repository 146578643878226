<template>
  <page-layout>
    <div class="heading">
      <h1 class="main-title">
        E-BPO, skintelligently designed<br />for rosacea<sup>1,8</sup>
      </h1>
      <div class="tabs-button">
        <router-link :to="{ name: 'summary' }">
          <button class="btn-tab1 omnes-med">SUMMARY</button>
        </router-link>
        <router-link :to="{ name: 'summary-access' }">
          <button class="btn-tab2 omnes-reg">ACCESS</button>
        </router-link>
      </div>
    </div>
    <div class="container">
      <div class="container__column-image">
        <img alt="" src="../assets/images/jpg-png/science-vehicle-v4.png" />
      </div>
      <div class="container__bullets">
        <span class="vertical-line1"></span><br />
        <span class="vertical-line2"></span><br />
        <span class="vertical-line3"></span>
      </div>

      <div class="container__product-description">
        <h3 class="subtitle-1">Science<sup>1,8</sup></h3>
        <p class="text-description1">
          Encapsulates BPO using <br />
          innovative technology
        </p>
        <span class="vertical-line2"></span>
        <h3 class="subtitle-2">Efficacy<sup>8,13</sup></h3>
        <p class="text-description2">
          Delivers relief as early as 2 weeks <br />
          that is sustained over time*
        </p>
        <span class="vertical-line3"></span>
        <h3 class="subtitle-3">Well Tolerated<sup>1</sup></h3>
        <p class="text-description3">
          Most adverse events were mild <br />
          to moderate in nature
        </p>
        <router-link :to="{ name: 'efficacy' }">
          <button class="btn-results">
            See results
            <span>
              <img
                class="arrow-black"
                src="@/assets/images/svg/arrow-2.svg"
                width="30"
                alt=""
              />
            </span>
          </button>
        </router-link>
        <div class="text-footer">
          <small class="omnes-reg"
            >*Efficacy was evaluated in two 12-week Phase 3 pivotal trials.<sup
              >1,8,13</sup
            ></small
          >
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";

export default {
  components: {
    PageLayout,
  },
};
</script>

<style lang="scss" scoped>
.summary {
  .heading {
    padding-top: 35px;
    padding-left: 42px;
    height: 193px;
    position: relative;
    box-sizing: border-box;
    .main-title {
      font-size: 53px;
      margin: 0;
      letter-spacing: 0.5px;
    }
    .tabs-button {
      position: absolute;
      bottom: 0;
      left: 42px;
      .btn-tab1,
      .btn-tab2 {
        font-size: 13px;
        letter-spacing: 0.8px;
        padding: 0;
        cursor: pointer !important;
        text-transform: uppercase;
        margin: 0;
        border: 0 none;
        width: 104px;
        height: 26px;
      }
      .btn-tab2 {
        background: $epsolay-modal;
        border-radius: none;
      }
      .btn-tab1 {
        background: $epsolay-dark-green-2;
        border-radius: none;
        color: $white;
      }
    }
  }
  .container {
    display: flex;
    width: 888px;
    height: 531px;
    background: $epsolay-dark-green-2;
    &__column-image {
      img {
        width: 640px;
        margin-top: -35px;
        margin-right: -120px;
        margin-left: -50px;
      }
    }
    &__bullets {
      margin-top: 93px;
      margin-left: 20px;
      .vertical-line1 {
        display: inline-block;
        border-left: 1px solid $epsolay-modal;
        margin-top: 14px;
        height: 70px;
      }
      .vertical-line2 {
        display: inline-block;
        border-left: 1px solid $epsolay-modal;
        margin-top: 22px;
        height: 65px;
      }
      .vertical-line3 {
        display: inline-block;
        border-left: 1px solid $epsolay-modal;
        margin-top: 20px;
        height: 65px;
      }
    }
    &__product-description {
      margin-top: 68px;
      margin-left: 20px;
      h3 {
        color: $epsolay-accent;
        font-family: "Omnes SemiBold";
        text-transform: uppercase;
        font-size: 19px;
      }
      .subtitle-1 {
        margin-top: 38px;
        margin-bottom: 10px;
      }
      .subtitle-2 {
        margin-top: 25px;
        margin-bottom: 10px;
      }
      .subtitle-3 {
        margin-top: 20px;
        margin-bottom: 10px;
      }
      sup {
        font-size: 20px;
        letter-spacing: 0.1px;
        font-weight: 600;
      }
      p {
        color: $white;
        font-family: "Omnes Regular";
        font-size: 17px;
        letter-spacing: 0px;
        margin-top: -10px;
        margin-bottom: 10px;
      }
      .btn-results {
        font-size: 9px;
        margin-top: 15px;
        margin-left: -20px;
        text-transform: uppercase;
        color: $white;
        border: 1px solid $white;
        background: transparent;
        padding: 10px;
        padding-left: 15px;
        padding-right: 15px;
        letter-spacing: 1.2px;
        cursor: pointer;
        img {
          margin-top: 2px;
          margin-left: 40px;
        }
      }
      .text-footer {
        position: absolute;
        left: 50px;
        bottom: 20px;
        small {
          font-size: 9.4px;
          color: #fff;
          sup {
            font-family: "Omnes Regular";
            font-weight: normal;
            font-style: normal;
            font-display: swap;
            font-size: 6px;
            vertical-align: super;
          }
        }
      }
    }
  }
}
</style>
