<template>
  <div>
    <h3 class="title-description omnes-reg">
      Adverse reactions reported by ≥1% of patients in the 12-week pivotal
      trials treated with <br />EPSOLAY cream and more frequently than with
      vehicle cream, all on application site<sup>1</sup>
    </h3>
    <img
      class="max-chart"
      src="@/assets/images/charts/adverse-reactions-table.svg"
    />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.adversereactionchartmax {
  .modal-layout__content {
    background-color: $epsolay-dark-green;
    height: 734px;
    width: 100%;
  }
  .modal-layout__close {
    background: transparent url(~@/assets/images/svg/close-button.svg) no-repeat;
    width: 30px;
    height: 30px;
    top: 28px;
    right: 28px;
  }
  .title-description {
    margin-top: 30px;
    margin-left: 5px;
    font-size: 20px;
    text-align: center;
    color: $epsolay-accent;
    font-weight: 600;
    sup {
      font-size: 18px;
    }
  }
  .max-chart {
    width: 650px;
    margin-left: 140px;
    margin-top: 40px;
  }
}
</style>
