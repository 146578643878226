<template>
  <div class="container">
    <h1 class="omnes-reg uppercase">Prescribing Information</h1>
    <div class="container-main-info">
      <img src="@/assets/images/jpg-png/pi-2.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.prescribinginformation {
  .modal-layout__content {
    padding: 30px 0 0;
  }
  .container {
    width: 100%;
    h1 {
      padding-left: 52px;
    }
    &-main-info {
      width: 875px;
      height: 430px;
      overflow-y: scroll;
      img {
        width: 100%;
      }
    }
  }
}
</style>
