<template>
  <page-layout>
    <div class="content">
      <div class="heading">
        <h1 class="main-title">Innovative microencapsulation technology</h1>
        <h3 class="subtitle">
          For the first time ever, BPO is suitable for sensitive rosacea
          skin<sup>1,2</sup>
        </h3>
      </div>
      <div class="tabs">
        <ul>
          <li class="mod-item">
            <router-link :to="{ name: 'science' }">MOD</router-link>
          </li>
          <li class="moa-item">
            <router-link :to="{ name: 'science-moa' }">MOA</router-link>
          </li>
          <li class="vehicle-item">
            <router-link :to="{ name: 'science-vehicle' }">Vehicle</router-link>
          </li>
          <li class="gradual-release-item">
            <router-link :to="{ name: 'science-gradual-release' }"
              >Gradual Release</router-link
            >
          </li>
        </ul>
      </div>
      <mod></mod>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";
import Mod from "@/components/Science/Mod.vue";

export default {
  components: {
    PageLayout,
    Mod,
  },
};
</script>

<style lang="scss">
.science {
  .content {
    .heading {
      margin-top: 30px;
      margin-left: 35px;
      .main-title,
      .subtitle {
        margin: 0;
      }
      .subtitle {
        margin-top: 5px;
      }
    }
    .tabs {
      position: absolute;
      left: -5px;
      top: 138px;
      ul {
        li {
          display: inline-block;
          a {
            text-decoration: none;
            text-transform: uppercase;
            font-family: "Omnes Regular";
            font-size: 14px;
            color: $epsolay-dark-green;
            background: $epsolay-tabs;
            display: inline-block;
            text-align: center;
            width: 104.63px;
            padding: 5px 0;
            &.router-link-exact-active {
              background: $epsolay-dark-green-2;
              color: $white;
              font-family: "Omnes Medium";
            }
          }
          &.moa-item {
            a {
              border-left: 1px solid $epsolay-dark-green;
              border-right: 1px solid $epsolay-dark-green;
            }
          }
          &.gradual-release-item {
            a {
              border-left: 1px solid $epsolay-dark-green;
              width: 160px;
            }
          }
        }
      }
    }
  }
}
</style>
