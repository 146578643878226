<template>
  <div class="container">
    <h3 class="title-description omnes-reg">
      Mean assessment of tolerability scores were below mild for both EPSOLAY
      cream <br />and vehicle cream throughout the pivotal study<sup>8,13</sup>
    </h3>
    <div class="buttons">
      <a
        :class="{ active: activeImage == 'twelve-weeks-study-1.svg' }"
        @click="showImage('twelve-weeks-study-1.svg')"
        >Study 1</a
      >
      <a
        :class="{ active: activeImage == 'twelve-weeks-study-2.svg' }"
        @click="showImage('twelve-weeks-study-2.svg')"
        >Study 2</a
      >
    </div>
    <img
      class="twelve-weeks-graph"
      :src="require('@/assets/images/svg/' + activeImage)"
      alt=""
    />
  </div>
</template>

<script>
import { sendTracking } from "@/mixins/tracking";

export default {
  data() {
    return {
      activeImage: `twelve-weeks-${this.$store.state.tolerabilityStudy}.svg`,
    };
  },
  methods: {
    showImage(name) {
      this.activeImage = name;
      if (name == "twelve-weeks-study-1.svg") {
        sendTracking(1005);
      } else {
        sendTracking(1007);
      }
    },
  },
};
</script>

<style lang="scss">
.tolerabilityweeksexpanded {
  .modal-layout__content {
    background-color: $epsolay-dark-green;
    height: 734px;
    width: 100%;
  }
  .modal-layout__close {
    background: transparent url(~@/assets/images/svg/close-button.svg) no-repeat;
    width: 30px;
    height: 30px;
    top: 28px;
    right: 28px;
  }
  .container {
    margin-left: 70px;
    margin-top: 40px;
    .title-description {
      margin-top: 30px;
      margin-left: 5px;
      font-size: 20px;
      text-align: center;
      color: $epsolay-accent;
      font-weight: 600;
      sup {
        font-size: 18px;
      }
    }
    .twelve-weeks-graph {
      margin-top: 40px;
    }
    .buttons {
      margin: auto 270px;
      width: 100%;
      a {
        text-decoration: none;
        color: white;
        font-family: "Omnes Condensed Medium";
        font-size: 18px;
        border: 1px solid white;
        padding: 8px 40px;
        display: inline-block;
        margin-right: 18px;
        &.active {
          background: $epsolay-btn-active;
        }
      }
    }
  }
}
</style>
