<template>
  <div class="gradual-release gradual-release-content">
    <div class="gradual-release-content-wrapper">
      <div class="analogue-reg intro-paragraph">
        The power of benzoyl peroxide, combined with microencapsulation
        technology <span class="omnes-med">(E-BPO)</span>
      </div>
      <hr class="horizontal-line" />
    </div>
    <div class="feature-content">
      <div class="col-1">
        <h2 class="omnes-med uppercase">
          <span class="omnes-sembold">E-BPO</span> proven to release<br />
          gradually over 24 Hours<sup>1,9*</sup>
        </h2>
        <p class="parag-1 omnes-cond-reg">
          Microencapsulated benzoyl peroxide (E-BPO) cream exhibits a
          <br />unique release profile compared to a commercial benzoyl
          <br />peroxide (Benzac<sup class="reg-mark">&reg;</sup> AC) formulated
          in a cream preparation <br />identical to that of E-BPO cream.
        </p>
        <p class="parag-2 omnes-cond-reg">
          E-BPO gradually releases BPO starting with 25% by 5 hours, <br />
          50% at 12 hours, and reaching peak concentration at 24 hours <br />
          allowing the skin to gradually absorb and adapt to BPO.<sup
            >1,2,9*</sup
          >
        </p>
      </div>
      <div class="col-2">
        <a @click="openModal('GradualReleaseModal')">
          <img
            class="expand-icon"
            src="@/assets/images/svg/expand-icon-v2.svg"
            alt=""
          />
        </a>
        <img src="@/assets/images/svg/bpo-chart.svg" alt="" />
      </div>
    </div>
    <hr class="horizontal-line footnote-hr" />
    <div class="footnote omnes-cond-reg">
      <p>E-BPO = Encapsulated benzoyl peroxide</p>
      <p>BPO = benzoyl peroxide</p>
      <p>
        *Release profile based on EPSOLAY in an in vitro setting. The analysis
        was performed in aqueous medium containing TWEEN 60 in Phosphate
        buffered solution at physiological temperature.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "gradual-release",
  methods: {
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
};
</script>

<style lang="scss" scoped>
.gradual-release {
  height: 100vh;
  margin-top: 76px;
  background: $epsolay-dark-green-2;
  padding-top: 20px;
  padding-left: 35px;
  padding-right: 155px;
  .intro-paragraph {
    color: white;
    font-size: 17px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
  .horizontal-line {
    border: 0.5px solid $epsolay-accent;
    opacity: 0.5;
    border-bottom: 0;
    margin-right: 4px;
    &.footnote-hr {
      margin-top: 30px;
    }
  }

  .feature-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    .col-1 {
      width: 40%;
      margin-right: 5%;
      text-align: center;
      color: white;
      h2 {
        font-size: 22px;
        &::after {
          content: "";
          display: block;
          width: 80%;
          height: 0.5px;
          background: rgba($color: $epsolay-accent, $alpha: 0.5);
          margin: 0 auto;
          margin-top: 15px;
        }
      }
      p {
        font-size: 12px;
      }
      .parag-1 {
        margin-top: 34px;
        &::after {
          content: "";
          display: block;
          width: 80%;
          height: 0.5px;
          background: rgba($color: $epsolay-accent, $alpha: 0.5);
          margin: 0 auto;
          margin-top: 35px;
        }
        .reg-mark {
          font-size: 14px;
          position: relative;
          top: 2px;
        }
      }
      .parag-2 {
        margin-top: 34px;
      }
    }
    .col-2 {
      width: 60%;
      position: relative;
      .expand-icon {
        width: 14px;
        position: absolute;
        right: 85px;
        top: 10px;
        z-index: 999;
      }
      img {
        width: 400px;
        position: relative;
      }
    }
  }
  .footnote {
    font-size: 7px;
    color: white;
    p {
      margin: 0;
    }
  }
}
</style>
