<template>
  <div class="container">
    <div class="col col-2">
      <div class="image-comparing">
        <img
          :src="require('@/assets/images/gallery/patient-1/' + activeImage)"
          alt=""
        />
      </div>
      <div class="btn-weeks">
        <a
          class="omnes-reg"
          :class="{ active: activeImage == 'patient-week-2.png' }"
          @click="toggleWeek('patient-week-2.png')"
          >Week 2</a
        >
        <a
          class="omnes-reg"
          :class="{ active: activeImage == 'patient-week-4.png' }"
          @click="toggleWeek('patient-week-4.png')"
          >Week 4</a
        >
        <a
          class="omnes-reg"
          :class="{ active: activeImage == 'patient-week-8.png' }"
          @click="toggleWeek('patient-week-8.png')"
          >Week 8</a
        >
        <img
          class="expand-icon"
          src="@/assets/images/svg/expand-icon.svg"
          @click="openModal('PatientOneGalleryExpanded')"
        />
      </div>
    </div>
    <div class="col col-3">
      <div class="info-success">
        <p class="omnes-med">Success</p>
      </div>
      <div class="patient-info">
        <p class="omnes-cond-reg">
          <span class="omnes-cond-bold">Study &nbsp; 54-02:</span> Double-blind,
          randomized, <br />
          vehicle-controlled safety and efficacy 12-week <br />
          trial of EPSOLAY cream vs vehicle cream.<sup>1</sup><br />
          <span class="omnes-cond-bold">Age:</span> 54 |
          <span class="omnes-cond-bold">Female</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { sendTracking } from "@/mixins/tracking";

export default {
  name: "PatientOne",
  data() {
    return {
      activeImage: "patient-week-2.png",
    };
  },
  methods: {
    showImage(name) {
      this.activeImage = name;
    },
    openModal(modalName) {
      this.toggleModal(modalName);
    },
    toggleWeek(image) {
      this.showImage(image);
      this.toggleGalleryWeek(["patientOne", image.slice(8, 14)]);
      switch (image.slice(8, 14)) {
        case "week-2":
          sendTracking(978);
          break;
        case "week-4":
          sendTracking(979);
          break;
        case "week-8":
          sendTracking(980);
          break;
      }
    },
  },
};
</script>

<style></style>
