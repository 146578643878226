<template>
  <div class="vehicle vehicle-content">
    <div class="vehicle-content-wrapper">
      <p class="tab-inner-title omnes-med">
        The <span class="omnes-sembold">EPSOLAY</span> vehicle contains:
      </p>
      <div class="vehicle-content__main">
        <div class="features">
          <div class="feature-item features-1">
            <img src="@/assets/images/jpg-png/icon-6.png" alt="" />
            <div class="info">
              <h5 class="omnes-sembold">Emollients<sup>1,16</sup></h5>
              <p class="omnes-cond-reg">
                Hydrate and help improve skin softness, <br />
                flexibility, and smoothness
              </p>
            </div>
          </div>
          <div class="feature-item features-2">
            <img src="@/assets/images/jpg-png/icon-7.png" alt="" />
            <div class="info">
              <h5 class="omnes-sembold">humectant<sup>1,17</sup></h5>
              <p class="omnes-cond-reg">Helps improve skin hydration</p>
            </div>
          </div>
          <div class="feature-item features-3">
            <img src="@/assets/images/svg/icon-7.svg" alt="" />
            <div class="info">
              <h5 class="omnes-sembold">
                Alpha-hydroxy acids<sup>1,18,19</sup>
              </h5>
              <p class="omnes-cond-reg">
                Exfoliate and smooth skin's appearance
              </p>
            </div>
          </div>
          <div class="feature-item features-4">
            <img src="@/assets/images/jpg-png/icon-8.png" alt="" />
            <h5 class="omnes-sembold">no Parabens<sup>1,20</sup></h5>
          </div>
        </div>
        <img
          class="feature-image"
          src="@/assets/images/jpg-png/science-vehicle-v3.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Vehicle",
};
</script>

<style lang="scss" scoped>
.vehicle-content {
  height: 100vh;
  margin-top: 71px;
  background: $epsolay-dark-green-2;
  padding-top: 20px;
  padding-left: 35px;
  padding-right: 155px;
  .tab-inner-title {
    color: $epsolay-accent-2;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1.2px;
    margin-right: 20px;
    border-bottom: 1px solid rgba($color: $epsolay-accent, $alpha: 0.5);
    padding-bottom: 15px;
  }
  &__main {
    position: relative;
    margin-left: 50px;
    margin-top: 60px;
    .features {
      h5 {
        color: $epsolay-accent;
        text-transform: uppercase;
        font-size: 15px;
        margin-top: 0;
        margin-bottom: 0;
        sup {
          font-size: 90%;
        }
      }
      p {
        font-size: 16px;
        color: $white;
        margin-top: 0;
        margin-bottom: 0px;
      }
      .feature-item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        img {
          width: 70px;
          margin-right: 20px;
        }
      }
    }
    .feature-image {
      position: absolute;
      top: -30px;
      left: 350px;
      width: 400px;
    }
  }
}
</style>
