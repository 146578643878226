<template>
  <div class="content">
    <div class="expanded-chart">
      <h2 class="omnes-med uppercase">
        <span class="omnes-sembold">E-BPO</span> proven to release<br />
        gradually over 24 Hours
      </h2>
      <img
        class="bpo-image-chart"
        src="@/assets/images/svg/bpo-chart.svg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang="scss">
.gradualreleasemodal {
  .modal-layout__content {
    width: 1050px;
    height: 734px;
    background: $epsolay-dark-green;
    .modal-layout__close {
      position: absolute;
      top: 60px;
      right: 19px;
      z-index: 100;
      width: 24px;
      height: 24px;
      background: url("../../assets/images/svg/close-button.svg") no-repeat;
      background-size: cover;
    }
    .content {
      display: flex;
      justify-content: space-between;
      margin-top: -30px;
      margin-left: -40px;
      height: 734px;
      .expanded-chart {
        position: absolute;
        top: 30px;
        left: 155px;
        h2 {
          color: white;
          text-align: center;
        }
      }
    }
  }
}
</style>
