<template>
  <page-layout>
    <div class="heading">
      <h1 class="main-title">Savings for your patients, simplified</h1>
      <div class="tabs-button">
        <router-link :to="{ name: 'summary' }">
          <button class="btn-tab1 omnes-reg">Summary</button>
        </router-link>
        <router-link :to="{ name: 'summary-access' }">
          <button class="btn-tab2 omnes-med">Access</button>
        </router-link>
      </div>
    </div>
    <div class="container">
      <div class="container-img">
        <img
          src="@/assets/images/jpg-png/galderma-connect-image-v2.png"
          alt=""
        />
      </div>
      <div class="container-info">
        <h3 class="title-description omnes-med uppercase">
          We're here to help <br />
          make sure your <br />patients can easily access
          <span class="omnes-sembold">EPSOLAY</span><sup>*</sup>
        </h3>
        <p>
          The Galderma CAREConnect Patient <br />
          Savings Card is designed to make it <br />
          easy for your patients to fill their <br />
          EPSOLAY prescription and pay less <br />
          out of pocket
        </p>
      </div>
      <div class="container__mini-text">
        <p class="supp omnes-cond-reg">
          The Galderma<sup>®</sup> CAREConnect<sup>TM</sup> Program (“Program”)
          is brought to you by Galderma Laboratories, L.P. (“Galderma”). The
          Program is only available at participating pharmacies for patients
          with commercial <br />
          insurance or patients without insurance. Patients who are enrolled in
          a state or federal government run or government sponsored healthcare
          plan can not participate in the Program. Any claim under the Program
          must be submitted by participating pharmacies to one of the
          Administrators of the Program. The Program is subject to applicable
          state and federal law and is void where prohibited by law, rule or
          regulation. In the event a lower cost generic drug that the FDA has
          designated as a therapeutic equivalent product is available for one of
          the Galderma products covered by the Program, or if the active
          ingredient of a Galderma product is available at a lower cost without
          a prescription, this offer will become void in California with respect
          to the Galderma product. You are encouraged to report negative side
          effects of prescription drugs to the FDA. Visit www.fda.gov/medwatch
          or call 1-800-FDA-1088. Insurance eligibility criteria will differ by
          plan. Patients are responsible for verifying their insurance
          eligibility criteria.
        </p>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";

export default {
  components: {
    PageLayout,
  },
};
</script>

<style lang="scss">
.summary-access {
  .container {
    background: $epsolay-dark-green-2;
    position: relative;
    display: inline-block;
    width: 888px;
    height: 531px;
    &-info {
      position: absolute;
      top: 121px;
      left: 512px;
      width: 340px;
      border-left: 1px solid $epsolay-accent;
      text-align: left;
      padding-left: 20px;
      box-sizing: border-box;
      h3 {
        margin: 0 0 18px 0;
        color: $epsolay-accent;
        font-size: 22px;
        line-height: 24px;
        letter-spacing: 0.25px;
        sup {
          font-size: 18px;
          font-family: "Omnes Bold";
          position: relative;
          top: -3px;
        }
      }
      p {
        color: $white;
        font-family: "Omnes Condensed Regular";
        font-size: 19px;
        margin: 0;
        letter-spacing: 0.18px;
      }
    }
    img {
      width: 570px;
      margin-top: 50px;
    }
    &__mini-text {
      position: absolute;
      bottom: 40px;
      left: 31px;
      line-height: 13px;
      width: 782px;
      p {
        color: $white;
        font-size: 10px;
        margin: 0;
        letter-spacing: 0.06px;
        position: relative;
        &::before {
          content: "*";
          position: absolute;
          top: 0;
          left: -5px;
        }
        sup {
          font-size: 60%;
          top: -2px;
          position: relative;
          font-family: "Omnes Condensed Regular";
          line-height: 0;
        }
      }
    }
  }
  .heading {
    padding-top: 59px;
    padding-left: 42px;
    height: 193px;
    position: relative;
    box-sizing: border-box;
    .main-title {
      font-size: 53px;
      margin: 0;
      letter-spacing: 0.4px;
    }
    .tabs-button {
      position: absolute;
      bottom: 0;
      left: 42px;
      .btn-tab1,
      .btn-tab2 {
        font-size: 13px;
        letter-spacing: 0.8px;
        padding: 0;
        cursor: pointer !important;
        text-transform: uppercase;
        margin: 0;
        border: 0 none;
        width: 104px;
        height: 26px;
      }
      .btn-tab1 {
        background: $epsolay-modal;
        border-radius: none;
      }
      .btn-tab2 {
        background: $epsolay-dark-green-2;
        border-radius: none;
        color: $white;
      }
    }
  }
}
</style>
