<template>
  <div>
    <h3 class="title-description omnes-reg">
      Facial cutaneous and tolerability assessment by severity at Week 52<sup
        >21</sup
      >
    </h3>
    <img
      class="max-chart"
      src="@/assets/images/charts/long-term-use-chart.svg"
    />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.longtermuseexpanded {
  .modal-layout__content {
    background-color: $epsolay-dark-green;
    height: 734px;
    width: 100%;
  }
  .modal-layout__close {
    background: transparent url(~@/assets/images/svg/close-button.svg) no-repeat;
    width: 30px;
    height: 30px;
    top: 28px;
    right: 28px;
  }
  .title-description {
    margin-top: 30px;
    margin-left: 5px;
    font-size: 20px;
    text-align: center;
    color: $epsolay-accent;
    font-weight: 600;
    sup {
      font-size: 18px;
    }
  }
  .max-chart {
    width: 800px;
    margin-left: 70px;
    margin-top: 40px;
  }
}
</style>
