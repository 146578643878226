<template>
  <div class="main-nav">
    <div class="main-logo">
      <img
        src="@/assets/images/svg/epsolay-logo-white.svg"
        @click="home()"
        alt=""
      />
    </div>
    <div class="nav-primary">
      <ul>
        <li class="rosacea-link">
          <router-link :to="{ name: 'rosacea-burden-tab' }"
            >Rosacea</router-link
          >
        </li>
        <li class="epsolay-cream-link">
          <router-link :to="{ name: 'epsolay-cream' }"
            >Epsolay Cream</router-link
          >
        </li>
        <li class="science-link">
          <router-link :to="{ name: 'science' }">Science</router-link>
        </li>
        <li class="efficacy-link">
          <router-link :to="{ name: 'efficacy' }">Efficacy</router-link>
        </li>
        <li class="tolerability-link">
          <router-link
            :to="{ name: 'tolerability-common-adverse-reactions-tab' }"
            >Tolerability</router-link
          >
        </li>
        <li class="access-link">
          <router-link :to="{ name: 'how-to-use' }">How to use</router-link>
        </li>
        <li class="summary-link">
          <router-link :to="{ name: 'summary' }">Summary</router-link>
        </li>
      </ul>
    </div>
    <div class="nav-secondary info-content">
      <li class="info-content-link isi-link">
        <a
          style="cursor: pointer"
          @click="openModal('ImportantSafetyInformationModal')"
          >Important safety <br />
          information</a
        >
      </li>
      <li class="info-content-link pi-link">
        <a @click="openPDF()">Prescribing <br />information</a>
      </li>
      <li class="info-content-link references-link">
        <a @click="openModal('ReferencesModal')">References</a>
      </li>
    </div>
  </div>
</template>

<script>
import { sendTracking, openPDF } from "@/mixins/tracking.js";

export default {
  name: "MainNavigation",
  computed: {
    mainNav() {
      return this.$store.state.mainNav;
    },
  },
  methods: {
    home() {
      return this.$router.push("/");
    },
    openModal(modalName) {
      this.toggleModal(modalName);
    },
    openPDF() {
      sendTracking(1041);
      const pdfFile = require("@/assets/pdf/epsolay-soolantra-pi.pdf");
      openPDF(pdfFile);
    },
  },
};
</script>

<style lang="scss">
// Mixins
@mixin nav-link-after($top) {
  content: "";
  border-bottom: 0.5px solid $epsolay-dark-green;
  height: 1px;
  width: 90%;
  position: absolute;
  top: $top;
  left: 8px;
}

.main-nav {
  background: $epsolay-dark-green;
  border-left: 1px solid $white;
  width: 149px;
  height: 100%;
  box-sizing: border-box;
  padding-top: 45px;

  .main-logo {
    margin-bottom: 56px;

    img {
      width: 79%;
      margin: 0 auto;
      display: block;
    }
  }

  .nav-primary {
    text-align: center;
    height: 449px;

    ul {
      margin: 0;
      padding: 0;
      height: 100%;

      li {
        line-height: 54px;

        a {
          color: $white;
          font-family: "Omnes Medium";
          letter-spacing: 1px;
          padding: 2px 8px;
          line-height: 20px;
          display: inline-block;

          &.router-link-active {
            border: 1px solid $epsolay-accent;
            font-family: "Omnes Semibold";
          }
        }
      }
    }
  }

  .nav-secondary {
    background: $epsolay-light-green;
    height: 146px;
    text-align: center;

    li {
      list-style: none;
      display: block;
      border-top: 1px solid $epsolay-dark-green;
      width: 90%;
      margin: 0 auto;

      &:first-child {
        border: 0 none;

        a {
          padding-top: 17px;
        }
      }

      a {
        text-decoration: none;
        font-family: "Omnes Regular";
        font-size: 12px;
        color: $white;
        text-transform: capitalize;
        margin: 0 auto;
        line-height: 15px;
        display: block;
        padding: 10px 0;
        letter-spacing: 10%;
      }
    }
  }

  ul {
    li {
      list-style: none;
      position: relative;
      text-align: center;

      a {
        text-decoration: none;
        font-family: "Omnes Regular";
        font-size: 12px;
        color: $white;
        text-transform: uppercase;
        display: inline-block;
      }
    }
  }
}
</style>
