<template>
  <page-layout>
    <div class="heading">
      <h1 class="main-title">Discover EPSOLAY</h1>
      <h3>
        The first-ever
        <span class="omnes-sembold">microencapsulated BPO (E-BPO)</span>
        <br />for rosacea<sup>1,2,8,9</sup>
      </h3>
    </div>
    <div class="container">
      <div class="container__list-images">
        <img class="bullet-1" src="../assets/images/svg/list-bullet-1.svg" />
        <img class="bullet-2" src="../assets/images/svg/list-bullet-2.svg" />
        <img class="bullet-3" src="../assets/images/svg/list-bullet-3.svg" />
        <img class="bullet-4" src="../assets/images/svg/list-bullet-3.svg" />
      </div>
      <div class="container__product-description">
        <div class="feature-item feature-1">
          <h3 class="subtitle-1">Designed for Rosacea</h3>
          <p class="text-description1">
            Although the precise mechanism of action of &nbsp;benzoyl peroxide
            (BPO) for <br />
            papulopustular rosacea (PPR) is unknown, BPO provides
            multi-factorial <br />
            benefits and addressed several of the key issues thought to be the
            root <br />
            causes of PPR, and acts as a potential anti-inflammatory,
            antimicrobial, <br />
            and anti-demodex.<sup class="omnes-reg">1,7,10-12</sup>
          </p>
        </div>

        <div class="feature-item feature-2">
          <h3 class="subtitle-2">GRADUAL RELEASE MICROENCAPSULATED BPO</h3>
          <p class="text-description2">
            EPSOLAY® gradually releases BPO using microencapsulation over 24
            <br />
            hours vs traditional immediate release, making it more suitable for
            <br />
            sensitive PPR skin. <sup class="omnes-reg">9</sup>
          </p>
        </div>

        <div class="feature-item feature-3">
          <h3 class="subtitle-3">TOLERABLE FOR SENSITIVE ROSACEA SKIN</h3>
          <p class="text-description3">
            The gradual release of microencapsulation technology allows E-BPO
            <br />
            to be well-tolerated, with all 4 mean tolerability measures
            (itching, <br />
            burning/stinging, dryness, scaling) scoring below mild and declining
            over <br />
            the course of the 12-week clinical study.
            <sup class="omnes-reg">1,2,8,13*</sup>
          </p>
        </div>

        <div class="feature-item feature-4">
          <h3 class="subtitle-3">
            FASTER RESULTS COMPARED TO SOOLANTRA<sup class="omnes-reg"
              >&reg;</sup
            >
            <br />
            (IVERMECTIN) CREAM, 1%
          </h3>
          <p class="text-description3">
            EPSOLAY (N=733) delivered rapid, superior inflammatory lesion count
            <br />
            reduction and IGA success compared with SOOLANTRA (N=1371).
            <sup class="omnes-reg">14†</sup>
          </p>
        </div>
      </div>
      <div class="text-footer omnes-reg">
        <p>
          *Microencapsulated BPO was studied in two, 12-week Phase 3 clinical
          studies. Efficacy at Week 2 was evaluated in a post-hoc analysis.
          <br />
          † As measured by inflammatory lesion reduction and IGA success.
          Comparable results were seen for both creams by Week 12.
        </p>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";

export default {
  components: {
    PageLayout,
  },
};
</script>

<style lang="scss">
.epsolay-cream {
  background: url("../assets/images/jpg-png/epsolay-cream-bg.png") no-repeat;
  background-size: 88%;
  .heading {
    .main-title {
      color: $white;
      font-family: "Analogue Regular";
      margin-top: 30px;
      font-size: 43px;
      margin-left: 48px;
      letter-spacing: 1.9px;
    }
    h3 {
      color: $epsolay-accent;
      font-family: "Omnes Regular";
      font-size: 26px;
      margin-top: -12px;
      margin-left: 48px;
      letter-spacing: 1px;
    }
    sup {
      font-size: 20px;
      letter-spacing: 0.1px;
      font-weight: 600;
    }
  }
  .container {
    display: flex;
    width: 807px;
    height: 600px;
    margin-left: 70px;
    &__list-images {
      display: flex;
      flex-direction: column;
      margin-top: 95px;
      .bullet-1 {
        width: 170px;
        height: 84px;
        margin-top: -80px;
        margin-left: 151px;
      }
      .bullet-2 {
        width: 114px;
        height: 87px;
        margin-top: 36px;
        margin-left: 207px;
      }
      .bullet-3 {
        width: 70px;
        height: 66px;
        margin-top: 35px;
        margin-left: 258px;
      }
      .bullet-4 {
        width: 70px;
        height: 66px;
        margin-top: 54px;
        margin-left: 258px;
      }
    }
    &__product-description {
      .feature-item {
        position: absolute;
        &.feature-1 {
          top: 90px;
        }
        &.feature-2 {
          top: 272px;
        }
        &.feature-3 {
          bottom: 200px;
        }
        &.feature-4 {
          bottom: 87px;
        }
      }

      h3 {
        color: $epsolay-accent;
        font-family: "Omnes SemiBold";
        text-transform: uppercase;
        font-size: 16px;
      }
      p {
        color: $white;
        font-family: "Omnes Regular";
        font-size: 14px;
        margin-left: 10px;
        letter-spacing: 0px;
      }
      sup {
        font-size: 16px;
        letter-spacing: 0.1px;
        font-weight: 600;
      }
      .subtitle-1 {
        margin-top: 79px;
        margin-bottom: 5px;
        margin-left: 10px;
        letter-spacing: 1.8px;
      }
      .subtitle-2 {
        margin-top: 40px;
        margin-bottom: 5px;
        margin-left: 10px;
        letter-spacing: 1.8px;
      }
      .subtitle-3 {
        margin-top: 33px;
        margin-left: 10px;
        letter-spacing: 1.8px;
        sup {
          font-size: 10px;
          position: relative;
          top: -8px;
        }
      }
      .text-description1 {
        margin-top: -5px;
        margin-left: 10px;
        letter-spacing: 0.1px;
        sup {
          font-size: 8px;
          position: relative;
          top: -6px;
        }
      }
      .text-description2 {
        margin-top: -5px;
        margin-left: 10px;
        letter-spacing: 0.2px;
        sup {
          font-size: 8px;
          position: relative;
          top: -6px;
        }
      }
      .text-description3 {
        margin-top: -15px;
        margin-left: 13px;
        letter-spacing: 0.2px;
        sup {
          font-size: 8px;
          position: relative;
          top: -6px;
        }
      }
    }
    .text-footer {
      position: absolute;
      bottom: 15px;
      left: 30px;
      color: $white;
      font-size: 10px;
      line-height: 16px;
    }
  }
}
</style>
