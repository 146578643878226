<template>
  <div class="moa moa-content">
    <div class="moa-content-wrapper">
      <div class="nav-section">
        <p class="nav-section-title omnes-med">
          The benefits of e-bpo for rosacea
        </p>
        <div class="buttons">
          <a
            @click="showContent('section-one')"
            :class="{ active: activeContent == 'section-one' }"
            class="link-one"
            >1</a
          >
          <a
            @click="showContent('section-two')"
            :class="{ active: activeContent == 'section-two' }"
            class="link-two"
            >2</a
          >
          <a
            @click="showContent('section-three')"
            :class="{ active: activeContent == 'section-three' }"
            class="link-three"
            >3</a
          >
        </div>
        <a
          class="mod-summary"
          @click="showContent('section-four')"
          :class="{ active: activeContent == 'section-four' }"
          >Mod Summary</a
        >
        <a
          href="#"
          class="watch-mod-video"
          @click="openModal('science-mod-video-modal')"
          >Watch Mod Video
          <span
            ><img src="@/assets/images/svg/mod-video-arrow.svg" alt="" /></span
        ></a>
      </div>

      <!-- Section One -->
      <div
        class="moa-content__section-one section"
        :class="{ active: activeContent == 'section-one' }"
      >
        <div class="col-1">
          <div class="built-in-barrier">
            <h5 class="omnes-sembold">Built-in barrier<sup>1,8</sup></h5>
            <p>
              Microencapsulation technology <br />
              features a shell structure made <br />
              from layers of silicon dioxide
            </p>
            <p>
              BPO, the active ingredient, <br />
              is encapsulated at the core <br />
              (ie, E-BPO)
            </p>
            <p>
              When applied, the shells <br />
              create a barrier between the <br />
              skin and the BPO
            </p>
          </div>
        </div>
        <div class="col-2">
          <div class="nav">
            <a
              class="left-nav"
              :class="{ 'opacity-arrow': activeContent == 'section-one' }"
              ><img
                @click="showContent('section-one')"
                src="~@/assets/images/svg/arrow.svg"
                alt=""
            /></a>
            <a class="right-nav"
              ><img
                @click="showContent('section-two')"
                src="~@/assets/images/svg/arrow.svg"
                alt=""
            /></a>
          </div>
          <img src="@/assets/images/jpg-png/slideshow-bg.png" alt="" />
        </div>
      </div>

      <!-- Section Two -->
      <div
        class="moa-content__section-two section"
        :class="{ active: activeContent == 'section-two' }"
      >
        <div class="col-1">
          <div class="controled-release">
            <h5 class="omnes-sembold">Controlled Release<sup>8</sup></h5>
            <p>
              Silica encapsulation controls the BPO release rate, allowing for
              gradual release over time
            </p>
          </div>
        </div>
        <div class="col-2">
          <div class="nav">
            <a class="left-nav"
              ><img
                @click="showContent('section-one')"
                src="~@/assets/images/svg/arrow.svg"
                alt=""
            /></a>
            <a class="right-nav"
              ><img
                @click="showContent('section-three')"
                src="~@/assets/images/svg/arrow.svg"
                alt=""
            /></a>
          </div>
          <img src="@/assets/images/jpg-png/slideshow-bg-2.png" alt="" />
        </div>
      </div>

      <!-- Section Three -->
      <div
        class="moa-content__section-three section"
        :class="{ active: activeContent == 'section-three' }"
      >
        <div class="col-1">
          <div class="tolerable-release">
            <h5 class="omnes-sembold">Tolerable Relief<sup>8</sup></h5>
            <p>
              The controlled release of BPO<br />
              over time allows for efficacious<br />
              yet tolerable relief of<br />
              inflammatory lesions of rosacea
            </p>
          </div>
        </div>
        <div class="col-2">
          <div class="nav">
            <a class="left-nav"
              ><img
                @click="showContent('section-two')"
                src="~@/assets/images/svg/arrow.svg"
                alt=""
            /></a>
            <a
              class="right-nav"
              :class="{ 'opacity-arrow': activeContent == 'section-three' }"
              ><img
                @click="showContent('section-three')"
                src="~@/assets/images/svg/arrow.svg"
                alt=""
            /></a>
          </div>
          <img
            class="mod-image"
            src="@/assets/images/jpg-png/slideshow-bg-3.png"
            alt=""
          />
        </div>
      </div>

      <!-- Mod Summary -->
      <div
        class="mod-summary-content section"
        :class="{ active: activeContent == 'section-four' }"
      >
        <div class="col-resume-1 resume">
          <span class="num one">1</span>
          <h5 class="omnes-sembold">Built-in barrier<sup>1,8</sup></h5>
          <p>
            Microencapsulation technology <br />
            features a shell structure made <br />
            from layers of silicon dioxide
          </p>
          <p>
            BPO, the active ingredient, <br />
            is encapsulated at the core <br />
            (ie, E-BPO)
          </p>
          <p>
            When applied, the shells creates a <br />
            barrier between the skin and the BPO
          </p>
        </div>
        <div class="col-resume-2 resume">
          <span class="num two">2</span>
          <h5 class="omnes-sembold">Controlled Release<sup>8</sup></h5>
          <p>
            Silica encapsulation controls the BPO release rate, allowing for
            gradual release over time
          </p>
        </div>
        <div class="col-resume-3 resume">
          <span class="num tree">3</span>
          <h5 class="omnes-sembold">Tolerable relief<sup>8</sup></h5>
          <p>
            The controlled release of BPO<br />over time allows for
            efficacious<br />yet tolerable relief of<br />inflammatory lesions
            of rosacea<br />
          </p>
        </div>
      </div>
      <hr />
      <small class="omnes-cond-reg">MOD=method of delivery.</small>
    </div>
  </div>
</template>

<script>
import { sendTracking } from "@/mixins/tracking";

export default {
  name: "Mod",
  data() {
    return {
      activeContent: "section-one",
    };
  },
  methods: {
    onSwipeLeft() {
      switch (this.activeContent) {
        case "section-one":
          this.showContent("section-two");
          break;
        case "section-two":
          this.showContent("section-three");
          break;
        case "section-three":
          this.showContent("section-four");
          break;
      }
    },
    onSwipeRight() {
      switch (this.activeContent) {
        case "section-four":
          this.showContent("section-three");
          break;
        case "section-three":
          this.showContent("section-two");
          break;
        case "section-two":
          this.showContent("section-one");
          break;
      }
    },
    showContent(content) {
      this.activeContent = content;
      switch (content) {
        case "section-one":
          sendTracking(966);
          this.$route.params.blockPrev = false;
          this.$route.params.blockNext = true;
          break;
        case "section-two":
          sendTracking(967);
          this.$route.params.blockPrev = true;
          this.$route.params.blockNext = true;
          break;
        case "section-three":
          sendTracking(968);
          this.$route.params.blockPrev = true;
          this.$route.params.blockNext = true;
          break;
        case "section-four":
          sendTracking(969);
          this.$route.params.blockPrev = true;
          this.$route.params.blockNext = false;
          break;
      }
    },
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
  mounted() {
    this.$route.params.blockPrev = false;
    this.$route.params.blockNext = true;
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
};
</script>

<style lang="scss" scoped>
@mixin btn-moa-section($padding1, $padding2) {
  font-family: "Omnes Semibold";
  color: $white;
  text-decoration: none;
  border: 1px solid $epsolay-accent;
  padding: $padding1 $padding2;
  border-radius: 50%;
}
.moa-content {
  height: 100vh;
  margin-top: 70px;
  background: $epsolay-dark-green-2;
  padding-top: 20px;
  padding-left: 35px;
  padding-right: 215px;
  .nav-section {
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid $epsolay-accent;
    padding-bottom: 20px;
    &-title {
      color: $epsolay-accent-2;
      font-size: 15px;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      margin-right: 20px;
    }
    .buttons {
      a {
        @include btn-moa-section(0, 8px);
        margin-right: 20px;
        &.active {
          background: $epsolay-accent;
          color: $epsolay-dark-green;
        }
      }
      .link-two,
      .link-three {
        padding: 0 6.5px;
      }
    }
    .mod-summary {
      font-family: "Omnes Semibold";
      font-size: 12px;
      text-decoration: none;
      color: $epsolay-accent;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      border: 1px solid $epsolay-accent;
      padding: 7px 10px;
      margin-right: 20px;
      &.active {
        background: $epsolay-accent;
        color: $epsolay-dark-green;
      }
    }
    .watch-mod-video {
      text-decoration: none;
      color: $white;
      font-family: "Omnes Regular";
      border: 1px solid $white;
      padding: 10px 18px;
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 1.2px;
      span {
        padding-left: 10px;
      }
    }
  }
  .section {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-left: 18px;
    .col-1 {
      text-align: center;
      .built-in-barrier {
        margin-right: 19px;
      }
      .controled-release {
        margin-top: 120px;
      }
      .tolerable-release {
        margin-top: 100px;
        margin-right: 15px;
      }
    }
    .col-2 {
      margin-left: 26px;
      margin-top: 15px;
      display: block;
      img {
        width: 520px;
      }
    }
    h5 {
      color: $epsolay-accent;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    p {
      font-family: "Omnes Condensed Regular";
      color: $white;
      &:nth-child(3) {
        border-top: 0.5px solid rgba($color: $white, $alpha: 0.2);
        padding: 10px 0;
        margin: 5px 0;
      }
      &:nth-child(4) {
        border-top: 0.5px solid rgba($color: $white, $alpha: 0.2);
        padding: 10px 0;
        margin: 5px 0;
      }
    }
    display: none;
    &.active {
      display: flex;
    }
  }
  &__section-one,
  &__section-two,
  &__section-three {
    margin-bottom: 46px;
    .col-2 {
      .nav {
        position: relative;
        width: 100%;
        .left-nav,
        .right-nav {
          position: absolute;
          top: 122px;
          width: 42px;
          height: 50px;
          display: flex;
          img {
            width: 23px;
          }
        }
        .left-nav {
          left: 0;
          justify-content: flex-end;
          opacity: 1;
          img {
            transform: rotate(180deg);
          }
          &.opacity-arrow {
            opacity: 0.6;
          }
        }
        .right-nav {
          right: 0;
          opacity: 1;
          &.opacity-arrow {
            opacity: 0.6;
          }
        }
      }
    }
  }
  &__section-three {
    .tolerable-release {
      margin-right: 7px !important;
    }
    .mod-image {
      margin-left: 10px;
    }
  }
  .mod-summary-content {
    text-align: center;
    margin-top: 0;
    margin-left: 0;
    height: 52vh;
    p {
      font-size: 17px;
    }
    h5 {
      font-size: 16px;
    }
    .resume {
      width: 33.3%;
      padding-top: 40px;
    }
    .num {
      color: $white;
      font-family: "Omnes Semibold";
      font-size: 22px;
      border: 1px solid $epsolay-accent;
      border-radius: 50%;
      padding: 2px 10px;
    }
    .col-resume-1 {
      padding-right: 20px;
      width: 35%;
      .one {
        padding: 2px 12px;
      }
      p:nth-child(3) {
        border: none;
        margin-bottom: 0;
      }
      p:nth-child(4) {
        border-top: 0.5px solid rgba($color: $epsolay-accent, $alpha: 0.4);
        border-bottom: 0.5px solid rgba($color: $epsolay-accent, $alpha: 0.4);
        padding: 10px 0;
      }
    }
    .col-resume-2 {
      background: $epsolay-dark-green;
      padding-right: 20px;
      padding-left: 20px;
      p {
        border: none;
      }
    }
    .col-resume-3 {
      padding-left: 20px;
      p:nth-child(3) {
        border: none;
        margin-bottom: -10px;
      }
      p:nth-child(4) {
        border-top: 0.5px solid rgba($color: $epsolay-accent, $alpha: 0.4);
        padding: 10px 0;
      }
    }
  }
  hr {
    border-top: 1px solid rgba($color: $epsolay-accent, $alpha: 0.3);
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    margin: 0;
    margin-bottom: 15px;
  }
  small {
    color: $white;
    font-size: 10px;
  }
}
</style>
