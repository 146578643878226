<template>
  <div :class="className" v-if="show">
    <div class="modal-layout__backdrop" @click="close"></div>
    <div class="modal-layout__content">
      <a class="modal-layout__close" @click="close"></a>
      <main>
        <slot> Please add some content to the modal. </slot>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    onClose: Function,
    show: Boolean,
  },
  data: function () {
    return {};
  },
  methods: {
    close: function () {
      this.onClose();
    },
  },
  computed: {
    className: function () {
      return [
        "modal-layout",
        "modal-layout-" +
          this.$route.name
            .replace(/([a-z])([A-Z])/g, "$1-$2")
            .replace(/\s+/g, "-")
            .toLowerCase()
            .split(":"),
        this.$store.state.modal.component.toLowerCase(),
        this.show ? "modal-layout--active" : undefined,
      ];
    },
    currentPage() {
      return this.$route.name;
    },
  },
};
</script>
<style lang="scss">
.modal-layout {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  outline: 0;
  height: 768px;
  overflow: hidden;
  z-index: 9999999999;
  align-items: center;
  justify-content: center;
  &--active {
    display: flex;
  }
  &__close {
    position: absolute;
    top: 15px;
    right: 19px;
    z-index: 100;
    width: 24px;
    height: 24px;
    background: transparent url("~@/assets/images/svg/close-button-2.svg")
      no-repeat;
    background-size: cover;
  }
  &__backdrop {
    z-index: 9999999999;
    background-color: #181b21;
    opacity: 0.75;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
  &__content {
    background: $epsolay-modal;
    min-width: 765px;
    padding: 31px 38px;
    box-sizing: border-box;
    position: relative;
    top: -27px;
    min-height: 504px;
    color: $epsolay-dark-green;
    h1 {
      font-size: 17px;
      letter-spacing: 1.5px;
      margin-bottom: 26px;
      margin-top: 0;
      font-family: "Omnes Condensed Regular";
    }
    p {
      font-size: 17px;
      letter-spacing: -0.43px;
      line-height: 1.24;
      margin-bottom: 19px;
      margin-top: 0;
      font-family: "Omnes Condensed Regular";
    }
  }
}
</style>
