<template>
  <page-layout>
    <div class="container-page">
      <div class="heading">
        <h1 class="main-title">
          Rosacea can be frustrating to manage<sup>7</sup>
        </h1>
      </div>
    </div>

    <div class="container">
      <div class="container__inner">
        <div class="tabs-buttons">
          <router-link :to="{ name: 'rosacea-burden-tab' }">
            <a><button class="btn-tab1 omnes-reg">BURDEN</button></a>
          </router-link>

          <router-link :to="{ name: 'rosacea-treatment-tab' }">
            <a><button class="btn-tab2 omnes-med">TREATMENT</button></a>
          </router-link>
        </div>
        <div class="container__title-patients">
          <h3 class="subtitle omnes-bold uppercase">Many patients:</h3>
        </div>

        <div class="container__content-columns">
          <div class="column-1">
            <img
              class="icon-1"
              src="@/assets/images/jpg-png/icon-4.png"
              width="80"
              alt=""
            />
            <h3 class="omnes-cond-bold">
              Struggle to find a treatment for<br />
              their sensitive rosacea skin<sup style="font-size: 18px">4</sup>
            </h3>
            <hr style="width: 240px; margin-top: 10px; margin-bottom: 1px" />
            <h4 class="omnes-reg" style="margin-bottom: 14px">IN A STUDY,</h4>
            <img
              class="icon-50-percent"
              src="@/assets/images/svg/less-50-percent.svg"
              alt=""
            />
            <p class="omnes-reg">
              <span class="omnes-sembold">of rosacea patients</span><br />
              were satisfied with their current<br />
              prescription treatments<sup style="font-size: 15px">4</sup>
            </p>
          </div>
          <hr class="middle-band" />
          <div class="column-2">
            <img
              class="icon-2"
              src="@/assets/images/jpg-png/icon-5.png"
              width="80"
              alt=""
            />
            <h3 class="omnes-cond-bold">
              Discontinue<br />
              treatment<sup style="font-size: 18px">4</sup>
            </h3>
            <hr style="width: 240px; margin-bottom: 1px" />
            <h4 class="omnes-reg">DUE TO:</h4>
            <ul>
              <li>Burdensome side effects<sup>4</sup></li>
              <li>Perceived poor long-term efficacy<sup>4</sup></li>
              <li>Slow onset of noticeable results<sup>4</sup></li>
            </ul>
          </div>
          <div class="column-3">
            <a @click="openLink" @touchend="openLink" target="_system">
              <button class="btn-survey-design">
                Experience their reality
                <span>
                  <img
                    class="arrow-white"
                    src="@/assets/images/svg/link-btn-green-arrow.svg"
                    width="80"
                    alt=""
                  />
                </span>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div class="container__footer">
        <p>
          Have your patients had trouble finding a treatment that's right for
          their skin?
        </p>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";
import { sendTracking } from "@/mixins/tracking";

export default {
  components: {
    PageLayout,
  },
  methods: {
    openLink() {
      sendTracking(964);
      window.open("https://www.aimforclear.com/rosacea", "_system");
    },
  },
};
</script>

<style lang="scss" scoped>
.rosacea-treatment-tab {
  button {
    color: black;
  }
  .icon-50-percent {
    height: 30px;
  }
  .container-page {
    width: 888px;
  }
  .heading {
    display: flex;
    align-items: center;
    margin: 0;
    height: 162px;
    letter-spacing: 1.5px;
    padding-left: 40px;
    h1 {
      margin: 0;
    }
  }
  .tabs-buttons {
    a {
      display: inline-block;
      color: black;
    }
    .btn-tab1,
    .btn-tab2 {
      font-size: 14px;
      letter-spacing: 0.8px;
      padding: 5px 22px;
      cursor: pointer;
      margin: 0;
      border: 0 none;
    }
    .btn-tab1 {
      background: $epsolay-accent-2a;
      font-weight: 500;
      border-radius: 0;
    }
    .btn-tab2 {
      background: $epsolay-accent-2;
      border-radius: none;
      font-weight: 500;
    }
  }
  .container {
    display: flex;
    justify-content: space-around;
    width: 888px;
    height: 562px;
    flex-flow: column nowrap;
    justify-content: space-between;
    &__inner {
      width: 803px;
      margin: 0 auto;
    }
    &__title-patients {
      text-align: center;
      border-top: 1px solid $epsolay-dark-text;
      border-bottom: 1px solid $epsolay-dark-text;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      .subtitle {
        letter-spacing: 0.8px;
        font-family: "Omnes Regular";
        font-weight: 600;
        color: $epsolay-dark-text;
        margin: 0;
        font-size: 17px;
      }
    }
    &__content-columns {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .column-1,
      .column-2,
      .column-3 {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 365px;
      }
      .column-1 {
        align-items: center;
        h3 {
          margin: 8px;
          font-size: 15.5px;
          text-align: center;
          letter-spacing: 0.4px;
        }
        p {
          font-size: 14.3px;
          text-align: center;
        }
        .icon-1 {
          margin-top: 32px;
          width: 70px;
        }
      }
      .middle-band {
        color: #ffede8;
        border: 3px solid #ffede8;
        height: 300px;
        display: inline-block;
        margin: 25px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .column-2 {
        display: flex;
        align-items: center;
        h3 {
          margin: 8px;
          font-size: 15.5px;
          text-align: center;
          letter-spacing: 0.4px;
        }
        ul {
          font-size: 14.3px;
          line-height: 25px;
          margin-top: 0px;
          margin-left: -35px;
          font-family: "Omnes regular";
        }
        li::marker {
          color: $epsolay-accent-2;
        }
        li > sup {
          font-size: 15px;
        }
        .icon-2 {
          width: 70px;
          margin-top: 34px;
        }
      }
      .column-3 {
        background-image: url("../assets/images/jpg-png/treatment-photo.png");
        background-repeat: no-repeat;
        background-size: cover;
        flex-grow: 15;
        position: relative;
        .btn-survey-design {
          font-size: 9px;
          position: absolute;
          bottom: 6%;
          width: 83%;
          left: 24px;
          text-transform: uppercase;
          color: #fff;
          border: 1px solid #fff;
          background: #3d8179;
          padding: 12px 5px 12px 4px;
          letter-spacing: 0.6px;
          cursor: pointer;
          cursor: pointer;
          span > .arrow-white {
            width: 28px;
            margin-bottom: 0;
            margin-left: 23px;
          }
        }
      }
    }
    &__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      background: #ffede8;
      p {
        font-family: "Omnes Regular";
        font-size: 15px;
        font-weight: 600;
        letter-spacing: -0.5px;
        margin: 0;
      }
    }
  }
}
</style>
