<template>
  <div class="container-expanded">
    <h1 class="omnes-reg patient-title">Patient 1</h1>
    <div class="nav-arrows">
      <div class="nav-arrows__prev">
        <img
          v-if="activeImage == 'patient-week-8.png'"
          class="prev"
          @click="showImage('patient-week-4.png')"
          src="@/assets/images/svg/previous-arrow.svg"
          alt=""
        />
        <img
          v-else-if="activeImage == 'patient-week-4.png'"
          class="prev"
          @click="showImage('patient-week-2.png')"
          src="@/assets/images/svg/previous-arrow.svg"
          alt=""
        />
        <img
          v-else
          class="prev default"
          src="@/assets/images/svg/previous-arrow.svg"
          alt=""
        />
      </div>
      <div class="nav-arrows__next">
        <img
          v-if="activeImage == 'patient-week-2.png'"
          class="next two"
          @click="showImage('patient-week-4.png')"
          src="@/assets/images/svg/next-arrow.svg"
          alt=""
        />
        <img
          v-else-if="activeImage == 'patient-week-4.png'"
          class="next four"
          @click="showImage('patient-week-8.png')"
          src="@/assets/images/svg/next-arrow.svg"
          alt=""
        />
        <img
          v-else
          class="next default"
          src="@/assets/images/svg/next-arrow.svg"
          alt=""
        />
      </div>
    </div>
    <img
      class="patient-expanded"
      :src="require('@/assets/images/gallery/patient-1/' + activeImage)"
      alt=""
    />
    <div class="btn-weeks">
      <a
        class="omnes-reg"
        :class="{ active: activeImage == 'patient-week-2.png' }"
        @click="showImage('patient-week-2.png')"
        >Week 2</a
      >
      <a
        class="omnes-reg"
        :class="{ active: activeImage == 'patient-week-4.png' }"
        @click="showImage('patient-week-4.png')"
        >Week 4</a
      >
      <a
        class="omnes-reg"
        :class="{ active: activeImage == 'patient-week-8.png' }"
        @click="showImage('patient-week-8.png')"
        >Week 8</a
      >
    </div>
  </div>
</template>

<script>
import { sendTracking } from "@/mixins/tracking";

export default {
  data() {
    return {
      activeImage: `patient-${this.$store.state.galleryWeek.patientOne}.png`,
    };
  },
  methods: {
    showImage(name) {
      this.activeImage = name;
      switch (name) {
        case "patient-week-2.png":
          sendTracking(981);
          break;
        case "patient-week-4.png":
          sendTracking(982);
          break;
        case "patient-week-8.png":
          sendTracking(983);
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.patientonegalleryexpanded {
  .modal-layout__content {
    background-color: $epsolay-dark-green;
    height: 734px;
    width: 100%;
  }
  .modal-layout__close {
    background: transparent url(~@/assets/images/svg/close-button.svg) no-repeat;
    width: 30px;
    height: 30px;
    top: 28px;
    right: 28px;
  }
  .container-expanded {
    margin-top: 50px;
    text-align: center;
    .patient-title {
      color: white;
      text-transform: uppercase;
      margin-bottom: 90px;
      font-size: 22px;
    }
    .nav-arrows {
      position: absolute;
      display: flex;
      width: 100%;
      .prev,
      .next {
        width: 60px;
        position: absolute;
        top: 120px;
        &.default {
          opacity: 0;
        }
      }
      .prev {
        left: 20px;
      }
      .next {
        right: 100px;
      }
    }
    .patient-expanded {
      width: 720px;
    }
    .btn-weeks {
      margin-top: 70px;
      margin-left: 7px;
      text-align: center;
      position: relative;
      a {
        font-size: 18px;
        color: $white;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 1.2px;
        margin-right: 10px;
        border: 1px solid $white;
        padding: 5px 7px;
        &.active {
          background: $epsolay-accent;
          color: $epsolay-dark-green;
          border: 1px solid $epsolay-accent;
        }
      }
    }
  }
}
</style>
