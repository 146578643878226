<template>
  <page-layout>
    <div class="content">
      <div class="heading">
        <h1 class="main-title">
          Superior Results Sooner Than <br />
          SOOLANTRA<sup class="reg-mark analogue-reg">&reg;</sup> (ivermectin)
          Cream, 1%<sup class="analogue-reg">14*</sup>
        </h1>
      </div>
      <div class="tabs">
        <ul>
          <li class="tab-item">
            <router-link :to="{ name: 'efficacy' }"
              >Pivotal trial results</router-link
            >
          </li>
          <li class="tab-item meta-analysis-onset-item">
            <router-link :to="{ name: 'meta-analysis-onset' }"
              >Meta-analysis (onset)</router-link
            >
          </li>
          <li class="tab-item iga-success-results">
            <router-link :to="{ name: 'iga-success' }"
              >Meta-analysis (IGA)</router-link
            >
          </li>
          <li class="p-photos-item">
            <router-link :to="{ name: 'patient-photos' }"
              >Patient Photos</router-link
            >
          </li>
        </ul>
      </div>
      <IgaSuccess></IgaSuccess>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";
import IgaSuccess from "@/components/Efficacy/IgaSuccess.vue";

export default {
  components: {
    PageLayout,
    IgaSuccess,
  },
};
</script>

<style lang="scss">
.iga-success {
  .content {
    .heading {
      margin-top: 55px;
      margin-left: 35px;
      .main-title {
        margin: 0;
        font-size: 38px;
        sup {
          font-size: 60%;
          top: -15px;
          position: relative;
          &.reg-mark {
            font-size: 20px;
            position: inline-block;
            top: -15px;
          }
        }
      }
    }
    .tabs {
      position: absolute;
      left: -22px;
      top: 137px;
      ul {
        li {
          display: inline-block;
          a {
            text-decoration: none;
            text-transform: uppercase;
            font-family: "Omnes Regular";
            font-size: 14px;
            color: $epsolay-dark-green;
            background: $epsolay-tabs;
            display: inline-block;
            text-align: center;
            letter-spacing: 1px;
            width: 200px;
            padding: 5px 0;
            &.router-link-exact-active {
              background: $epsolay-dark-green-2;
              color: $white;
              font-family: "Omnes Medium";
            }
          }
          &.p-photos-item {
            a {
              border-left: 1px solid $epsolay-dark-green;
            }
          }
          &.meta-analysis-onset-item {
            a {
              padding-left: 20px;
              padding-right: 20px;
              border-left: 1px solid $epsolay-dark-green;
            }
          }
        }
      }
    }
  }
}
</style>
