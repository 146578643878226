<template>
  <page-layout>
    <div class="heading">
      <h1 class="main-title">Well tolerated over 12 weeks<sup>8,13</sup></h1>
    </div>
    <div class="tabs-button">
      <router-link :to="{ name: 'tolerability-common-adverse-reactions-tab' }">
        <button class="btn-tab1 omnes-reg">COMMON ADVERSE REACTIONS</button>
      </router-link>
      <router-link :to="{ name: 'tolerability-twelve-weeks' }">
        <button class="btn-tab3 omnes-med">TOLERABILITY OVER 12 WEEKS</button>
      </router-link>
      <router-link :to="{ name: 'tolerability-long-term-use-tab' }">
        <button class="btn-tab2 omnes-reg">LONG-TERM USE</button>
      </router-link>
    </div>
    <div class="container">
      <h3 class="title-description omnes-reg">
        Mean assessment of tolerability scores were below mild for both EPSOLAY
        cream <br />and vehicle cream throughout the pivotal study<sup
          >8,13</sup
        >
      </h3>
      <div class="buttons">
        <a
          :class="{ active: activeImage == 'twelve-weeks-study-1.svg' }"
          @click="toggleStudy('twelve-weeks-study-1.svg')"
          >Study 1</a
        >
        <a
          :class="{ active: activeImage == 'twelve-weeks-study-2.svg' }"
          @click="toggleStudy('twelve-weeks-study-2.svg')"
          >Study 2</a
        >
      </div>
      <img
        class="expand-icon"
        src="@/assets/images/svg/expand-icon.svg"
        alt=""
        @click="openModal('TolerabilityWeeksExpanded')"
      />
      <img
        class="twelve-weeks-graph"
        :src="require('@/assets/images/svg/' + activeImage)"
        alt=""
      />
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";
import { sendTracking } from "@/mixins/tracking";

export default {
  components: {
    PageLayout,
  },
  data() {
    return {
      activeImage: "twelve-weeks-study-1.svg",
    };
  },
  methods: {
    showImage(name) {
      this.activeImage = name;
    },
    openModal(modalName) {
      this.toggleModal(modalName);
    },
    toggleStudy(image) {
      this.showImage(image);
      this.toggleTolerabilityStudy(
        image == "twelve-weeks-study-1.svg" ? "study-1" : "study-2"
      );
      if (image == "twelve-weeks-study-1.svg") {
        sendTracking(1004);
      } else {
        sendTracking(1006);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tolerability-twelve-weeks {
  .heading {
    margin-top: 60px;
    margin-left: 40px;
  }
  .tabs-button {
    margin-top: 55px;
    margin-left: 40px;
    margin-bottom: -2px;
    .btn-tab1,
    .btn-tab2,
    .btn-tab3 {
      color: $epsolay-dark-green;
      font-size: 13px;
      letter-spacing: 0.8px;
      padding-left: 15px;
      padding-right: 20px;
      cursor: pointer !important;
      margin: 0;
    }
    .btn-tab1 {
      background: $epsolay-modal;
      border: 1px solid $epsolay-modal;
      border-radius: none;
      padding: 7px 10px;
    }
    .btn-tab2 {
      background: $epsolay-modal;
      border: 1px solid $epsolay-modal;
      border-radius: none;
      padding: 7px 50px;
    }
    .btn-tab3 {
      color: $white;
      background: $epsolay-dark-green;
      padding-top: 5px;
      border: 1px solid $epsolay-dark-green;
      border-radius: none;
      padding: 7px 20px;
    }
  }
  .container {
    display: inline-block;
    width: 900px;
    height: 560px;
    background: $epsolay-dark-green;
    .title-description {
      margin-top: 30px;
      margin-left: -70px;
      font-size: 16px;
      text-align: center;
      color: $epsolay-accent;
      font-weight: 600;
      sup {
        font-size: 18px;
      }
    }
    .buttons {
      margin: auto 270px;
      width: 100%;
      a {
        text-decoration: none;
        color: white;
        font-family: "Omnes Condensed Medium";
        font-size: 18px;
        border: 1px solid white;
        padding: 8px 40px;
        display: inline-block;
        margin-right: 18px;
        &.active {
          background: $epsolay-btn-active;
        }
      }
    }
    .expand-icon {
      position: absolute;
      right: 420px;
      top: 294px;
      z-index: 2;
    }
    .twelve-weeks-graph {
      width: 610px;
      position: absolute;
      left: 113px;
      bottom: -9px;
      position: relative;
      z-index: 1;
    }
  }
}
</style>
