<template>
  <page-layout>
    <div class="container">
      <!-- Main Header -->
      <header>
        <h1 class="main-title">EPSOLAY cream application instructions</h1>
        <h2 class="omnes-reg">
          <span class="omnes-sembold"
            >Patients can use it daily as prescribed</span
          >, in either the morning or evening<sup>1</sup>
        </h2>
      </header>
      <!-- Subtitle -->
      <div class="subtitle d-flex cc">
        <h3 class="omnes-med">
          USING <span class="omnes-sembold">EPSOLAY CREAM</span> IS AS EASY AS
          C-A-M-P:
        </h3>
      </div>
      <div class="d-flex cards">
        <!-- card one -->
        <div class="card">
          <span class="bullet">1</span>
          <img src="@/assets/images/jpg-png/icon-10.png" class="icon" alt="" />
          <h4>cleanse</h4>
          <p>
            Use a mild cleanser like <br />
            <span
              >Cetaphil<sup>&reg;</sup> Gentle Skin <br />
              Cleanser</span
            >, gently rinse your face, and pat completely <br />
            dry with a soft towel.<sup>1</sup>
          </p>
        </div>
        <!-- card two -->
        <div class="card">
          <span class="bullet">2</span>
          <img class="icon" src="@/assets/images/jpg-png/icon-11.png" alt="" />
          <h4>apply</h4>
          <p>
            Apply a pea-sized amount of EPSOLAY once daily in a thin layer to
            <span>each</span> area of the face (forehead, chin, nose, each
            cheek). Avoid your eyes, lips, and mouth and let dry.
          </p>
          <p>
            Wash your hands, as the cream may stain or bleach fabric.<sup
              >1</sup
            >
          </p>
        </div>
        <!-- card three -->
        <div class="card">
          <span class="bullet">3</span>
          <img class="icon" src="@/assets/images/jpg-png/icon-12.png" alt="" />
          <h4>moisturize</h4>
          <p>
            After the medication has <br />
            dried, use a moisturizer <br />
            like
            <span
              >Cetaphil<sup>&reg;</sup> <br />
              Moisturizing Lotion.</span
            ><sup>1</sup>
          </p>
        </div>
        <!-- card four -->
        <div class="card">
          <span class="bullet">4</span>
          <img class="icon" src="@/assets/images/jpg-png/icon-13.png" alt="" />
          <h4>protect</h4>
          <p>
            If you decide to use <br />
            EPSOLAY as part of &nbsp;your morning routine, be sure to <br />
            use a daily sunscreen to <br />protect your skin!<sup>1</sup>
          </p>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";

export default {
  components: {
    PageLayout,
  },
};
</script>

<style lang="scss">
.how-to-use {
  .container {
    width: 889px;
    height: 100%;
  }
  header {
    height: 193px;
    box-sizing: border-box;
    padding: 0 0 0 42px;
    color: $epsolay-dark-green;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    color: $epsolay-dark-green;
    sup {
      font-size: 23px;
    }
    h1,
    h2 {
      margin: 0;
    }
    h1 {
      letter-spacing: 0.74px;
      margin-bottom: 8px;
    }
    h2 {
      letter-spacing: 0.49px;
    }
  }

  .subtitle {
    font-size: 18px;
    color: $epsolay-accent-2;
    height: 82px;
    background: $epsolay-dark-green;
    border-bottom: 1px solid $epsolay-accent;
    h3 {
      margin: 4px 0 0 4px;
      font-size: 20px;
      letter-spacing: 0.3px;
    }
  }
  .cards {
    height: 448px;
    background: $epsolay-dark-green;
    .card {
      width: 222px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      text-align: center;
      box-sizing: border-box;
      padding: 37px 10px;
      height: 100%;
      sup {
        font-size: 18px;
      }
      &:nth-child(3) {
        padding: 37px 9px 37px 7px;
      }
      &:nth-child(4) {
        padding: 37px 9px 37px 7px;
      }
      &:nth-child(even) {
        background: $epsolay-dark-green-2;
      }
      .bullet {
        background: $white;
        height: 25px;
        width: 25px;
        display: block;
        border-radius: 50%;
        line-height: 26px;
        color: $epsolay-dark-green;
        margin-bottom: 23px;
        font-family: "Omnes SemiBold";
      }
      img {
        width: 76px;
        margin-bottom: 11px;
      }
      h4 {
        color: $epsolay-accent;
        margin: 0 0 19px 0;
        display: block;
        text-transform: uppercase;
        font-family: "Omnes SemiBold";
        width: 93%;
        padding-bottom: 20px;
        border-bottom: 1px solid $epsolay-accent;
      }
      p {
        color: $white;
        margin: 0;
        font-size: 17px;
        letter-spacing: 0.1px;
        line-height: 20px;
        font-family: "Omnes Condensed Regular";
        margin-bottom: 6px;
        span {
          font-family: "Omnes Condensed Bold";
        }
      }
    }
  }
}
</style>
