<template>
  <div class="iga-success-results content">
    <div class="content-wrapper">
      <div class="analogue-reg intro-paragraph">
        Based on a meta-analysis comparing inflammatory lesion count reduction
        and IGA success in <br />
        4 (<span class="analogue-ita">two</span> for EPSOLAY,
        <span class="analogue-ita">two</span> for SOOLANTRA) randomized,
        controlled, double-blind, Phase 3 trials:<sup class="analogue-reg"
          >14</sup
        >
      </div>
      <hr class="horizontal-line" />
      <div class="chart-content">
        <h3 class="chart-title omnes-sembold">
          ~3X more subjects achieved IGA success (Clear or Almost Clear) at Week
          2 and Week 4 <br />
          with EPSOLAY compared with SOOLANTRA<sup>14</sup><sup>†</sup>
        </h3>
        <div class="chart-images">
          <a @click="openModal('TwoWeekIgaSuccessModal')">
            <img
              class="expand-icon expand-icon-first"
              src="@/assets/images/svg/expand-icon.svg"
              alt=""
            />
          </a>
          <img
            class="iga-two-week"
            src="@/assets/images/svg/iga-two-week.svg"
            alt=""
          />
          <a @click="openModal('FourWeekIgaSuccessModal')">
            <img
              class="expand-icon expand-icon-second"
              src="@/assets/images/svg/expand-icon.svg"
              alt=""
            />
          </a>
          <img
            class="iga-four-week"
            src="@/assets/images/svg/iga-four-week.svg"
            alt=""
          />
        </div>
        <p class="chart-feature-text omnes-med">
          Which of your patients using SOOLANTRA could benefit from faster
          results as seen with EPSOLAY?
        </p>
      </div>
      <div class="study-design-btn">
        <a @click="openModal('EfficacyPivotalTrialResultsModal')"
          >See study design
          <span
            ><img src="@/assets/images/svg/mod-video-arrow.svg" alt="" /></span
        ></a>
      </div>
      <hr class="horizontal-line footnote-hr" />
      <div class="footnote omnes-cond-reg">
        <p>
          *As measured by lesion reduction and IGA success. Comparable results
          were seen for both creams by Week 12.
        </p>
        <p>
          <sup>†</sup>The studies were designed independently and were not
          designed as comparative studies. A meta-analysis was performed using
          the Bucher Meta-analysis statistical method. Studies included 4 total
          (two for EPSOLAY, two for SOOLANTRA) multicenter, randomized,
          double-blind, vehicle-controlled trials <br />
          of efficacy and safety for both EPSOLAY cream and SOOLANTRA.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
};
</script>

<style lang="scss">
.iga-success-results.content {
  height: 100vh;
  margin-top: 47px;
  background: $epsolay-dark-green-2;
  padding-left: 35px;
  padding-right: 140px;
  .content-wrapper {
    position: relative;
    padding-top: 20px;
    .intro-paragraph {
      font-size: 16px;
      color: white;
      text-align: center;
      line-height: 1.4;
      letter-spacing: 0.4px;
      margin-bottom: 17.5px;
      sup {
        font-size: 60%;
        top: -5px;
        position: relative;
      }
    }
    .horizontal-line {
      border: 0.5px solid $epsolay-accent;
      opacity: 0.5;
      border-bottom: 0;
      margin-right: 13px;
      &.footnote-hr {
        margin-top: 30px;
      }
    }
    .chart-content {
      margin-top: 15px;
      .chart-title {
        font-size: 14px;
        color: $epsolay-accent;
        text-align: center;
        margin-bottom: 0;
        margin-right: 20px;
        sup {
          font-size: 16px;
          top: 2px;
          position: relative;
        }
      }
      .chart-images {
        display: flex;
        margin-left: 20px;
        margin-top: 20px;
        margin-bottom: 51.5px;
        position: relative;
        .expand-icon {
          position: absolute;
          width: 8.8px;
          z-index: 999;
          width: 12px;
          &-first {
            left: 334px;
            top: 0;
          }
          &-second {
            right: 60px;
            top: 0;
          }
        }
        .iga-two-week {
          width: 377px;
          margin-right: 42px;
        }
        .iga-four-week {
          width: 377px;
        }
      }
      .chart-feature-text {
        font-size: 13px;
        color: white;
        text-align: center;
        margin-top: -10px;
        sup {
          font-size: 14px;
          top: 2px;
          position: relative;
        }
      }
    }
    .study-design-btn {
      text-align: center;
      margin-top: 20px;
      a {
        text-decoration: none;
        color: $white;
        font-family: "Omnes Regular";
        border: 1px solid $white;
        padding: 10px 18px;
        text-transform: uppercase;
        font-size: 8px;
        letter-spacing: 1.2px;
      }
      span {
        padding-left: 10px;
      }
    }
    .footnote {
      font-size: 7px;
      color: white;
      p {
        margin: 0;
      }
    }
  }
}
</style>
