import Vue from "vue";
import Vuex from "vuex";
import { sendTracking } from "@/mixins/tracking";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    modal: {
      component: undefined,
      isVisible: false,
    },
    mainNav: {
      isVisible: false,
    },
    tolerabilityStudy: "study-1",
    galleryWeek: {
      patientOne: "week-2",
      patientTwo: "week-2",
      patientThree: "week-2",
      patientFour: "week-2",
    },
  },
  mutations: {
    toggleTolerabilityStudy(state, payload) {
      state.tolerabilityStudy = payload;
    },
    toggleGalleryWeek(state, payload) {
      state.galleryWeek[payload[0]] = payload[1];
    },
    toggleModal(state, payload) {
      if (payload) {
        state.modal.component = payload;
        state.modal.isVisible = true;
        if (payload == "rosacea-burden-survey-modal") {
          sendTracking(1017);
        }
        if (payload == "science-mod-video-modal") {
          sendTracking(970);
        }
        if (payload == "EfficacyTrialResultsData1") {
          sendTracking(974);
        }
        if (payload == "EfficacyTrialResultsData2") {
          sendTracking(975);
        }
        if (payload == "EfficacyTrialResultsData3") {
          sendTracking(976);
        }
        if (payload == "EfficacyPivotalTrialResultsModal") {
          sendTracking(977);
        }
        if (payload == "PatientOneGalleryExpanded") {
          switch (state.galleryWeek.patientOne) {
            case "week-2":
              sendTracking(981);
              break;
            case "week-4":
              sendTracking(982);
              break;
            case "week-8":
              sendTracking(983);
              break;
          }
        }
        if (payload == "PatientTwoGalleryExpanded") {
          switch (state.galleryWeek.patientTwo) {
            case "week-2":
              sendTracking(987);
              break;
            case "week-4":
              sendTracking(988);
              break;
            case "week-8":
              sendTracking(989);
              break;
          }
        }
        if (payload == "PatientThreeGalleryExpanded") {
          switch (state.galleryWeek.patientThree) {
            case "week-2":
              sendTracking(993);
              break;
            case "week-4":
              sendTracking(994);
              break;
            case "week-8":
              sendTracking(995);
              break;
          }
        }
        if (payload == "PatientFourGalleryExpanded") {
          switch (state.galleryWeek.patientFour) {
            case "week-2":
              sendTracking(999);
              break;
            case "week-4":
              sendTracking(1000);
              break;
            case "week-8":
              sendTracking(1001);
              break;
          }
        }

        if (payload == "AdverseReactionChartMax") {
          sendTracking(1003);
        }

        if (payload == "TolerabilityWeeksExpanded") {
          if (state.tolerabilityStudy == "study-1") {
            sendTracking(1005);
          } else {
            sendTracking(1007);
          }
        }

        if (payload == "LongTermUseExpanded") {
          sendTracking(1009);
        }
        if (payload == "SeeStudyDesign") {
          sendTracking(1010);
        }

        if (payload == "ImportantSafetyInformationModal") {
          sendTracking(1014);
        }
        if (payload == "PrescribingInformation") {
          sendTracking(1015);
        }
        if (payload == "ReferencesModal") {
          sendTracking(1016);
        }
      } else {
        state.modal.isVisible = !state.modal.isVisible;
      }
    },
    toggleMainNav(state, payload) {
      if (payload) {
        state.mainNav.isVisible = true;
      } else {
        state.mainNav.isVisible = !state.mainNav.isVisible;
      }
    },
  },
  actions: {},
});
