<template>
  <div class="content">
    <div class="content__text-description">
      <h4 class="omnes-reg">STUDY DESIGN<sup>1</sup></h4>
      <p class="omnes-cond-reg">
        The safety and efficacy of EPSOLAY cream were evaluated in 2
        multicenter, randomized, double-blind, vehicle-<br />
        controlled trials in patients with moderate to severe papulopustular
        rosacea. In Pivotal Trial 1, 243 patients<br />
        used EPSOLAY cream and 118 used vehicle cream. In Pivotal Trial 2, 250
        patients used EPSOLAY cream and 122<br />
        used vehicle cream.
      </p>
      <p class="omnes-cond-reg">
        Patients were treated once daily for 12 weeks with either EPSOLAY cream
        or vehicle cream.
      </p>
      <p class="omnes-cond-reg">
        Patients were required to have a minimum of 15 to 70 total inflammatory
        lesions (papules and/or pustules) and<br />
        no more than 2 nodules (where a
        <span class="omnes-ita">nodule</span> was defined as a papule or pustule
        greater than 5 mm in diameter)<br />
        and an Investigator Global Assessment (IGA) score of 3 (moderate) or 4
        (severe) at baseline.
      </p>
      <p class="omnes-cond-reg">
        At baseline, patients had a mean inflammatory lesion count of 27.5, 89%
        scored as moderate (IGA=3), and 11%<br />
        scored as severe (IGA=4).
      </p>
      <p class="omnes-cond-reg">
        The coprimary efficacy endpoints in both trials were the proportion of
        patients with
        <span class="omnes-ita">treatment success at </span><br /><span
          class="omnes-ita"
          >Week 12,</span
        >
        defined as an IGA score of 0 (clear) or 1 (almost clear) with at least a
        2-grade reduction from baseline,<br />
        and the absolute change from baseline in inflammatory lesion counts at
        Week 12.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.efficacy-pivotal-trial-results-modal {
  .modal-layout__content {
    background: #d8d4fa;
    padding: 30px;
    .content {
      &__text-description {
        margin-left: 20px;
        h4 {
          font-size: 16.5px;
          letter-spacing: 1px;
          sup {
            font-size: 15.8px;
          }
        }
        p {
          span {
            font-size: 15px;
          }
        }
        sup {
          font-size: 10px;
        }
      }
    }
  }
}
</style>
