<template>
  <div class="pivotal-trial-results content">
    <div class="content-wrapper">
      <div class="col data-1">
        <h5 class="omnes-med">in as little as</h5>
        <img
          class="feature-image"
          src="@/assets/images/svg/2-weeks.svg"
          alt=""
        />
        <p class="omnes-reg">
          patients saw <br />
          visible results<sup>8,13</sup>
        </p>
        <a
          href="#"
          @click="openModal('EfficacyTrialResultsData1')"
          class="see-data omnes-sembold"
        >
          See data
          <span>
            <img src="@/assets/images/svg/see-data-bars.svg" alt="" />
          </span>
        </a>
      </div>
      <div class="col data-2">
        <h5 class="omnes-med">Reduced nearly</h5>
        <img
          class="feature-image"
          src="@/assets/images/svg/70-percent-efficacy.svg"
          alt=""
        />
        <p class="omnes-reg">
          of inflammatory <br />
          lessions by Week 12<sup>1</sup>
        </p>
        <a
          href="#"
          @click="openModal('EfficacyTrialResultsData2')"
          class="see-data omnes-sembold"
        >
          See data
          <span>
            <img src="@/assets/images/svg/see-data-bars.svg" alt="" />
          </span>
        </a>
      </div>
      <div class="col data-3">
        <h5 class="omnes-med">Many patients were</h5>
        <img
          class="feature-image"
          src="@/assets/images/svg/almost-clear.svg"
          alt=""
        />
        <a
          href="#"
          @click="openModal('EfficacyTrialResultsData3')"
          class="see-data omnes-sembold"
        >
          See data
          <span>
            <img src="@/assets/images/svg/see-data-bars.svg" alt="" />
          </span>
        </a>
      </div>
    </div>
    <div class="study-design-btn">
      <a @click="openModal('EfficacyPivotalTrialResultsModal')"
        >See study design
        <span><img src="@/assets/images/svg/mod-video-arrow.svg" alt="" /></span
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
};
</script>

<style lang="scss">
.pivotal-trial-results.content {
  height: 100vh;
  margin-top: 70px;
  background: $epsolay-dark-green-2;
  padding-left: 35px;
  padding-right: 140px;
  .content-wrapper {
    display: flex;
    justify-content: center;
    border-bottom: 0.5px solid $epsolay-accent;
    .col {
      width: 33.3%;
      padding-top: 20px;
      text-align: center;
      h5 {
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 1.2px;
        padding-top: 30px;
      }
      p {
        font-size: 16px;
        letter-spacing: 1.2px;
        sup {
          font-size: 100%;
        }
      }
      .see-data {
        text-decoration: none;
        text-transform: uppercase;
        color: $epsolay-accent;
        font-size: 10px;
        border: 1px solid $epsolay-accent;
        padding: 8px 15px 8px 15px;
        display: inline-block;
        margin-top: 30px;
        margin-bottom: 40px;
        img {
          width: 21.4px;
        }
      }
      &.data-1 {
        padding-right: 30px;
        h5 {
          border-top: 0.5px solid $epsolay-accent;
        }
        .feature-image {
          width: 153px;
        }
        p,
        h5 {
          color: $epsolay-accent;
        }
        sup {
          letter-spacing: -0.3px;
        }
      }
      &.data-2 {
        padding-left: 30px;
        padding-right: 30px;
        background: $epsolay-dark-green;
        h5 {
          border-top: 0.5px solid $white;
        }
        .feature-image {
          width: 140px;
        }
        p,
        h5 {
          color: $white;
        }
      }
      &.data-3 {
        padding-left: 30px;
        padding-right: 30px;
        h5 {
          color: $epsolay-accent-2;
          border-top: 0.5px solid $epsolay-accent-2;
        }
        .feature-image {
          width: 213px;
        }
      }
    }
  }
  .study-design-btn {
    text-align: center;
    margin-top: 35px;
    a {
      text-decoration: none;
      color: $white;
      font-family: "Omnes Regular";
      border: 1px solid $white;
      padding: 10px 18px;
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 1.2px;
    }
    span {
      padding-left: 10px;
    }
  }
}
</style>
