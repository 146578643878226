<template>
  <div>
    <div id="app">
      <v-touch
        v-on:swipeleft="onSwipeLeft"
        v-on:swiperight="onSwipeRight"
        ref="tapper"
      >
        <router-view />
      </v-touch>
    </div>
    <div id="modals">
      <modal-layout :show="modal.isVisible" :on-close="toggleModal">
        <component :is="modal.component"></component>
      </modal-layout>
    </div>
  </div>
</template>
<script>
import ModalLayout from "@/components/common/ModalLayout.vue";

export default {
  components: {
    ModalLayout,
  },
  methods: {
    onSwipeLeft() {
      if (!this.$route.params.blockNext) {
        if (this.lastPage === this.currentPage) {
          this.next = 0;
        } else {
          this.next = this.currentPage + 1;
        }
        this.$router.push(this.routes[this.next]);
      } else {
        this.$route.params.onSwipeLeft();
      }
    },
    onSwipeRight() {
      if (!this.$route.params.blockPrev) {
        if (this.currentPage === 0) {
          this.prev = this.lastPage;
        } else {
          this.prev = this.currentPage - 1;
        }
        this.$router.push(this.routes[this.prev]);
      } else {
        this.$route.params.onSwipeRight();
      }
    },
  },
  computed: {
    currentPage() {
      this.routes.forEach((route, i) => {
        if (route.name === this.$route.name) {
          // eslint-disable-next-line
          this.page = i
        }
      });
      return this.page;
    },
    mainNav() {
      return this.$store.state.mainNav;
    },
    modal() {
      return this.$store.state.modal;
    },
  },
  beforeMount() {
    this.routes = [];
    this.$router.options.routes.forEach((route) => {
      this.routes.push(route);
    });
    this.lastPage = this.routes.length - 1;
    const previousRoute = localStorage.getItem("epsolayV1.actualUrl");
    if (previousRoute) {
      const routeExists = this.$router.resolve(previousRoute);
      if (routeExists.resolved.matched.length > 0) {
        this.$router.push(previousRoute);
      }
    }
  },
  watch: {
    $route: function (route) {
      localStorage.setItem("epsolayV1.actualUrl", route.fullPath);
    },
  },
};
</script>
<style lang="scss">
#app {
  width: 1024px;
  height: 768px;
  overflow: hidden;
}

body {
  // Font "Smooth" on Photoshop
  -webkit-font-smoothing: antialiased;
}
</style>
