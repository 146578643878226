<template>
  <div :class="className">
    <main>
      <slot></slot>
    </main>
    <div class="sidebar">
      <main-navigation></main-navigation>
    </div>
  </div>
</template>
<script>
import MainNavigation from "@/components/common/MainNav";

export default {
  components: {
    MainNavigation,
  },
  props: {
    navbarType: {
      type: String,
      default() {
        return "a";
      },
    },
  },
  data: function () {
    return {
      patient: this.$store.state.patient,
    };
  },
  created: function () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "togglePatient") {
        this.patient = state.patient;
      }
    });
  },
  computed: {
    className: function () {
      return [
        "page",
        this.patient,
        this.$route.name
          .replace(/([a-z])([A-Z])/g, "$1-$2")
          .replace(/\s+/g, "-")
          .toLowerCase()
          .split(":"),
      ];
    },
  },
};
</script>
<style lang="scss">
.page {
  // height: 768px - 44px iDash Area;
  height: 724px;
  overflow: hidden;
  position: relative;
  .sidebar {
    position: absolute;
    top: 0;
    right: 0;
    width: 149px;
    height: 100vh;
    background: rgba(255, 255, 0, 0.178);
  }
}
</style>
