<template>
  <div class="moa moa-content">
    <div class="moa-content-wrapper">
      <p class="tab-inner-title omnes-med">
        THE PRECISE MECHANISM OF ACTION (MOA) OF BPO IN THE TREATMENT OF <br />
        THE INFLAMMATORY LESIONS OF ROSACEA IS UNKNOWN<sup class="script-med"
          >1</sup
        >
      </p>
      <div class="moa-content__main">
        <div class="content-text">
          <p class="omnes-cond-reg">
            BPO is a trusted compound with complementary properties <br />
            that are thought to effectively treat PPR. <sup>1,7,10,15</sup>
          </p>
        </div>
        <div class="col-img">
          <img src="@/assets/images/jpg-png/epsolay-molecule-v3.png" alt="" />
        </div>
        <div class="img-vectors">
          <img
            class="pointer pointer-1"
            src="@/assets/images/svg/list-bullet-2.svg"
          />
          <img
            class="pointer pointer-2"
            src="@/assets/images/svg/list-bullet-2.svg"
          />
          <img
            class="pointer pointer-3"
            src="@/assets/images/svg/list-bullet-2.svg"
          />
          <img
            class="pointer pointer-4"
            src="@/assets/images/svg/list-bullet-2.svg"
          />
        </div>
        <div class="col-info">
          <div class="col-info__one">
            <h5 class="omnes-sembold">
              Potential Anti-inflammatory effects<sup class="omnes-reg">15</sup>
            </h5>
            <p class="omnes-cond-reg">
              BPO is known to kill bacteria and may help reduce <br />
              inflammation in PPR
            </p>
          </div>
          <div class="col-info__two">
            <h5 class="omnes-sembold">
              Potential Antimicrobial effects<sup class="omnes-reg">1,11</sup>
            </h5>
            <p class="omnes-cond-reg">
              BPO is converted into benzoic acid in the skin and <br />
              releases free-radical oxygen
            </p>
          </div>
          <div class="col-info__three">
            <h5 class="omnes-sembold">
              ANTI-DEMODEX DEMONSTRATED<sup class="omnes-reg">1,7,12</sup>
            </h5>
            <p class="omnes-cond-reg">
              Demonstrated to be effective in killing Demodex, <br />
              which is believed to play a key role in rosacea's pathogenesis
            </p>
          </div>
          <div class="col-info__three">
            <h5 class="omnes-sembold">
              no antibiotic resistance<sup class="omnes-reg">1</sup>
            </h5>
            <p class="omnes-cond-reg">
              BPO is not an antibiotic and there is no evidence <br />
              of antibiotic resistance
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Moa",
};
</script>

<style lang="scss" scoped>
.moa-content {
  height: 100vh;
  margin-top: 59px;
  background: $epsolay-dark-green-2;
  padding-top: 20px;
  padding-left: 35px;
  padding-right: 155px;
  .tab-inner-title {
    color: $epsolay-accent-2;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1.2px;
    margin-right: 20px;
    border-bottom: 1px solid rgba($color: $epsolay-accent, $alpha: 0.5);
    padding-bottom: 15px;
  }
  &__main {
    position: relative;
    margin-bottom: 148px;
    .content-text {
      display: flex;
      justify-content: center;
      text-align: center;
      p {
        margin-top: -10px;
        font-size: 16.5px;
        color: $white;
        sup {
          font-size: 18px;
        }
      }
    }
    .col-img {
      width: 40%;
      position: absolute;
      top: 67px;
      left: -20px;
      img {
        width: 330px;
      }
    }
    .img-vectors {
      position: absolute;
      left: 260px;
      top: 64px;
      img {
        width: 95px;
      }
      .pointer {
        display: block;
        &-1 {
          margin-top: -4px;
        }
        &-2 {
          width: 70px;
          margin-top: 45px;
          margin-left: 25px;
        }
        &-3 {
          width: 70px;
          margin-top: 53px;
          margin-left: 25px;
        }
        &-4 {
          margin-top: 50px;
        }
      }
    }
    .col-info {
      width: 60%;
      position: absolute;
      right: -30px;
      top: 34px;
      h5 {
        sup {
          font-size: 8px;
          position: relative;
          top: -6px;
        }
      }
      &__one {
        margin-top: -10px;
        h5 {
          margin-top: 16px;
        }
      }
      &__two {
        h5 {
          margin-top: 36px;
        }
      }
      &__three {
        h5 {
          margin-top: 30px;
        }
      }
      h5 {
        color: $epsolay-accent;
        text-transform: uppercase;
        font-size: 14px;
        margin-top: 28px;
        margin-bottom: 0;
      }
      p {
        font-size: 16px;
        color: $white;
        margin-top: 3px;
        margin-bottom: 0px;
        border: none;
      }
    }
  }
  hr {
    border-top: 1px solid rgba($color: $epsolay-accent, $alpha: 0.3);
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    margin-top: 400px;
    margin-bottom: 15px;
  }
  small {
    color: $white;
    font-size: 10px;
  }
}
</style>
