<template>
  <page-layout>
    <div class="heading">
      <h1 class="main-title">
        Delicate on skin, making it suitable for <br />long-term use<sup
          style="letter-spacing: -2px"
          >1,8,13,21</sup
        >
      </h1>
    </div>
    <div class="tabs-button">
      <router-link :to="{ name: 'tolerability-common-adverse-reactions-tab' }">
        <button class="btn-tab1 omnes-reg">COMMON ADVERSE REACTIONS</button>
      </router-link>
      <router-link :to="{ name: 'tolerability-twelve-weeks' }">
        <button class="btn-tab3 omnes-reg">TOLERABILITY OVER 12 WEEKS</button>
      </router-link>
      <router-link :to="{ name: 'tolerability-long-term-use-tab' }">
        <button class="btn-tab2 omnes-med">LONG-TERM USE</button>
      </router-link>
    </div>
    <div class="container">
      <h3 class="title-description omnes-reg">
        Facial cutaneous and tolerability assessment by severity at Week 52<sup
          >21</sup
        >
      </h3>
      <img
        class="expand-icon"
        src="@/assets/images/svg/expand-icon.svg"
        alt=""
        @click="openModal('LongTermUseExpanded')"
      />
      <img
        class="study-design-img"
        src="@/assets/images/charts/long-term-use-chart.svg"
      />
      <button @click="openModal('SeeStudyDesign')" class="btn-survey-design">
        See Study design
        <span class="btn-arrow">
          <img src="@/assets/images/svg/arrow-2.svg" alt="" />
        </span>
      </button>
    </div>
    <hr class="footer-bar" />
    <div class="footnote">
      <p class="omnes-med">
        Long-term safety results were comparable to results of the two 12-week
        studies.<sup>1</sup>
      </p>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";

export default {
  components: {
    PageLayout,
  },
  methods: {
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
};
</script>

<style lang="scss" scoped>
.tolerability-long-term-use-tab {
  .heading {
    margin-top: 36px;
    margin-left: 40px;
    letter-spacing: 0.8px;
  }
  .tabs-button {
    margin-top: 33px;
    margin-left: 40px;
    margin-bottom: -3px;
    .btn-tab1,
    .btn-tab2,
    .btn-tab3 {
      color: $epsolay-dark-green;
      font-size: 13px;
      letter-spacing: 0.8px;
      padding-left: 15px;
      padding-right: 20px;
      cursor: pointer !important;
      margin: 0;
    }
    .btn-tab1 {
      background: $epsolay-modal;
      border: 1px solid $epsolay-modal;
      border-radius: none;
      padding: 7px 10px;
      border-right: 1px solid $epsolay-dark-green;
    }
    .btn-tab2 {
      color: $white;
      background: $epsolay-dark-green;
      padding-top: 5px;
      padding-left: 40px;
      padding-right: 40px;
      border: 1px solid $epsolay-dark-green;
      border-radius: none;
      padding: 7px 50px;
    }
    .btn-tab3 {
      background: $epsolay-modal;
      border: 1px solid $epsolay-modal;
      border-left: 1px solid $epsolay-dark-green;
      border-radius: none;
      padding: 7px 20px;
    }
  }
  .container {
    display: inline-block;
    width: 900px;
    height: 560px;
    background: $epsolay-dark-green;
    .title-description {
      margin-top: 46px;
      margin-left: -15px;
      font-size: 16px;
      text-align: center;
      color: $epsolay-accent;
      font-weight: 600;
      letter-spacing: 0.3px;
      sup {
        font-size: 18px;
      }
    }
    .expand-icon {
      position: absolute;
      right: 276px;
      top: 287px;
      z-index: 2;
    }
    .study-design-img {
      width: 560px;
      height: 480px;
      position: absolute;
      bottom: 60px;
      left: 165px;
      z-index: 1;
    }
    .btn-survey-design {
      background: $epsolay-dark-green;
      border: 1px solid $white;
      color: $white;
      text-transform: uppercase;
      font-size: 9.7px;
      padding: 10px;
      position: absolute;
      cursor: pointer;
      bottom: 30px;
      left: 364px;
      z-index: 2;
      .btn-arrow {
        img {
          width: 34px;
          margin-left: 10px;
        }
      }
    }
  }
  .footer-bar {
    border: 0.5px solid #c0b6fb;
    width: 830px;
    margin-left: 30px;
    z-index: 999999;
    position: absolute;
    bottom: 78px;
  }
  .footnote {
    position: absolute;
    bottom: 105px;
    left: 170px;
    p {
      color: #fff;
      font-size: 15.5px;
      sup {
        font-size: 110%;
      }
    }
  }
}
</style>
