import Vue from "vue";
import Router from "vue-router";

// Import views
import Home from "@/views/Home.vue";
import RosaceaBurdenTab from "@/views/RosaceaBurdenTab.vue";
import RosaceaTreatmentTab from "@/views/RosaceaTreatmentTab.vue";
import EpsolayCream from "@/views/EpsolayCream.vue";
import Science from "@/views/Science.vue";
import ScienceMoa from "@/views/ScienceMoa.vue";
import ScienceVehicle from "@/views/ScienceVehicle.vue";
import ScienceGradualRelease from "@/views/ScienceGradualRelease.vue";
import Efficacy from "@/views/Efficacy.vue";
import MetaAnalysisOnSet from "@/views/EfficacyMetaAnalysisOnset.vue";
import IgaSuccess from "@/views/EfficacyIgaSuccessResults.vue";
import PatientPhotos from "@/views/PatientPhotos.vue";
import TolerabilityCAdverseReactionsTab from "@/views/TolerabilityCAdverseReactionsTab.vue";
import TolerabilityTwelveWeeks from "@/views/TolerabilityTwelveWeeks.vue";
import TolerabilityLTermUseTab from "@/views/TolerabilityLTermUseTab.vue";
import HowToUse from "@/views/HowToUse.vue";
import Summary from "@/views/Summary.vue";
import SummaryAccess from "@/views/SummaryAccess.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        datapointId: 961,
      },
    },
    {
      path: "/rosacea-burden-tab",
      name: "rosacea-burden-tab",
      component: RosaceaBurdenTab,
      meta: {
        datapointId: 962,
      },
    },
    {
      path: "/rosacea-burden-tab/rosacea-treatment-tab",
      name: "rosacea-treatment-tab",
      component: RosaceaTreatmentTab,
      meta: {
        datapointId: 963,
      },
    },
    {
      path: "/epsolay-cream",
      name: "epsolay-cream",
      component: EpsolayCream,
      meta: {
        datapointId: 965,
      },
    },
    {
      path: "/science",
      name: "science",
      component: Science,
      meta: {
        datapointId: 966,
      },
    },
    {
      path: "/science/moa",
      name: "science-moa",
      component: ScienceMoa,
      meta: {
        datapointId: 971,
      },
    },
    {
      path: "/science/vehicle",
      name: "science-vehicle",
      component: ScienceVehicle,
      meta: {
        datapointId: 972,
      },
    },
    {
      path: "/science/gradual-release",
      name: "science-gradual-release",
      component: ScienceGradualRelease,
      meta: {
        datapointId: 972,
      },
    },
    {
      path: "/efficacy",
      name: "efficacy",
      component: Efficacy,
      meta: {
        datapointId: 973,
      },
    },
    {
      path: "/efficacy/meta-analysis-onset",
      name: "meta-analysis-onset",
      component: MetaAnalysisOnSet,
      meta: {
        datapointId: 973,
      },
    },
    {
      path: "/efficacy/iga-success-results",
      name: "iga-success",
      component: IgaSuccess,
      meta: {
        datapointId: 973,
      },
    },
    {
      path: "/efficacy/patient-photos",
      name: "patient-photos",
      component: PatientPhotos,
      meta: {
        datapointId: 978,
      },
    },
    {
      path: "/tolerability-common-adverse-reactions-tab",
      name: "tolerability-common-adverse-reactions-tab",
      component: TolerabilityCAdverseReactionsTab,
      meta: {
        datapointId: 1002,
      },
    },
    {
      path: "/tolerability-common-adverse-reactions-tab/tolerability-twelve-weeks",
      name: "tolerability-twelve-weeks",
      component: TolerabilityTwelveWeeks,
      meta: {
        datapointId: 1004,
      },
    },
    {
      path: "/tolerability-common-adverse-reactions-tab/tolerability-long-term-use-tab",
      name: "tolerability-long-term-use-tab",
      component: TolerabilityLTermUseTab,
      meta: {
        datapointId: 1008,
      },
    },
    {
      path: "/how-to-use",
      name: "how-to-use",
      component: HowToUse,
      meta: {
        datapointId: 1011,
      },
    },
    {
      path: "/summary",
      name: "summary",
      component: Summary,
      meta: {
        datapointId: 1012,
      },
    },
    {
      path: "/summary/summary-access",
      name: "summary-access",
      component: SummaryAccess,
      meta: {
        datapointId: 1013,
      },
    },
  ],
});

// Based on https://router.vuejs.org/guide/advanced/navigation-failures.html
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
